import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {checkHistorico, checkOnlineValues, getWidgetIcon, addThousandsAndReplaceDecimal, addZeroes, GetBatteryStatus} from '../helpers';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ReorderIcon from '@material-ui/icons/CompareArrowsOutlined';
import Equalizer from '@material-ui/icons/EqualizerOutlined';
import Settings from '@material-ui/icons/SettingsOutlined';
import { IObjects, IData } from '../types';
import { Bar, Line } from 'react-chartjs-2';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    drawerPaper: {
        position: 'absolute',
        top:143,
    },
    card: {
        position:'relative',
        zIndex:0,
        //maxWidth:'275px',
        maxWidth:'auto',
        color: '#fff',
        border:'0px solid',
        borderRadius:'15px'
    },
    cardDate: {
        color:'#fff',
        opacity:0.75,
        fontSize:12,
        fontWeight:'bold',
    },
    cardTitle:{
        fontWeight:'bold',
        fontSize:18
    },
    cardValue: {
        fontWeight:'bold',
        fontSize:25 
    },
    media: {
        height: '40px',
        width:'40px'
    },
});

const cardChartOpts = {
    tooltips: {
      enabled: true,
      //custom: CustomTooltips
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          barPercentage: 0.6,
        }],
        yAxes: [{
          ticks: {
              display: true,
              beginAtZero:true, 
              fontColor: '#fff'
          }
        }]
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
};

function GetWidgetColor(value:number, max:number,  min:number){
    if(value < min){
        return('#A3D1F3');
    } else if(value >= min && value <= max){
        return('#98CCA1');
        //return('#20c997');
    } else if(value > max){
        return('#E44A40');
    } else {
        return('#BBBBBB');
    }
}

function SelectChart(typeChart:number, data:any){
    switch(typeChart) {
    case 1:
        return(<Line data={data} options={cardChartOpts} height={80} />)
    case 2:
        return(<Bar data={data} options={cardChartOpts} height={80} />)
    default:
        break;
    }
}

function formatterField(data:number, ud:string){
    if(typeof data === 'number')
        return `${addThousandsAndReplaceDecimal(addZeroes(Number(data.toFixed(2))),',')} ${ud}`;
    return data;
}

interface IPropsWidgets{
    pObject:IObjects;
    editing: boolean;
    handleSettingsClick: Function;
    handleChartClick: Function;
    handleDeleteClick: Function;
    handleBatteryClick: Function;
    handleObjectReorder: Function;
    dataDevices?:Array<IData>;
}
// Widget con gráfico en la parte inferior
export default function Widget04(props: IPropsWidgets) {
    const classes = useStyles();
    const data = checkOnlineValues(props.pObject.dsCode, props.pObject.dataType, props.dataDevices);
    const historic = checkHistorico(props.pObject.dsCode, props.pObject.dataType, props.dataDevices);
    const BatteryData = props.pObject.battery?GetBatteryStatus(props.pObject.battery.status):undefined;
    const chartData01 = 
    {
        labels: historic[0],
        datasets: [{
            //label: `this.props.children`,
            backgroundColor: 'rgba(255,255,255,.3)',
            borderColor: 'transparent',
            pointHoverBackgroundColor: '#fff',
            //borderWidth: 2,
            data: historic[1]
        }],
    };
    const chartData02 = 
    {labels: historic[0],
        datasets: [
          {
            label: props.pObject.description,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: historic[1],
          },
        ],
    };

    return(
        <div style={{position:'relative'}}>
            {props.editing?
                <div style={{position:'absolute', top:0, right:0, zIndex:90}} >
                    {/*<IconButton style={{marginRight:20}} onClick={()=>props.handleObjectReorder(props.pObject)} aria-label="reorder">
                        <ReorderIcon />
            </IconButton>*/}
                    <IconButton size="small" onClick={()=>props.handleDeleteClick(props.pObject)} aria-label="view chart" >
                        <RemoveCircleIcon />
                    </IconButton>
                </div>
            :
                null
            }
            <div>
                <Card className={classes.card} style={{backgroundColor:GetWidgetColor(data[0], props.pObject.highValue, props.pObject.lowValue)}}>

                    <CardContent>
                        <CardMedia
                            className={classes.media}
                            image={getWidgetIcon(props.pObject.deviceTypeId)}
                            title="widget icon"
                        />
                        <Typography className={classes.cardTitle}>{props.pObject.description}</Typography>
                        <Typography className={classes.cardDate} style={{marginBottom:10}}>{`${data[1]}`}</Typography>
                        <Typography className={classes.cardValue}>{`${formatterField(data[0], props.pObject.ud)}`}</Typography>
                    </CardContent>
                    <div style={{ height: '70px', paddingRight:10, paddingLeft:10}}>
                        {SelectChart(props.pObject.chartType, chartData01)}
                    </div>
                    <CardActions disableSpacing>
                        <IconButton onClick={()=>props.handleChartClick(props.pObject, chartData02)} aria-label="view chart" >
                            <Equalizer />
                        </IconButton>
                        <IconButton onClick={()=>props.handleSettingsClick(props.pObject)} aria-label="settings">
                            <Settings />
                        </IconButton>

                        {BatteryData?
                            <IconButton size="small" onClick={()=>props.handleBatteryClick(props.pObject, BatteryData)} style={{position:'absolute', bottom: 10, right: 0}} aria-label="battery">
                                {BatteryData[0]}
                            </IconButton>
                        :
                            null
                        }
                    </CardActions>
                </Card>
            </div>
        </div>
    );
}

export {
    Widget04
}