import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        listStyle: 'none',
    },
});

interface IVirtualizedListProps {
    classes: any;
    items: Array<any>;
    RowComponent: any;
    itemSize: number;
}

interface IVirtualizedListState {
    height: number;
    width: number;
}

class VirtualizedList extends Component<IVirtualizedListProps, IVirtualizedListState> {
    listContainerRef: any;

    constructor(props: IVirtualizedListProps) {
        super(props);
        this.state = {
            height: 400,
            width: 360,
        }

        this.listContainerRef = React.createRef();
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        if (this.listContainerRef !== undefined && this.listContainerRef !== null && this.listContainerRef.current !== undefined && this.listContainerRef.current !== null) {
            const node = this.listContainerRef.current;
            this.setState({
                height: node.offsetHeight,
                width: node.offsetWidth,
            })
        }
    }

    render() {
        const { classes, items } = this.props;

        return (
            <div className={classes.root} ref={this.listContainerRef}>
                <FixedSizeList
                    height={this.state.height}
                    width={this.state.width}
                    itemSize={this.props.itemSize}
                    itemData={items}
                    itemCount={items.length}>
                    {this.props.RowComponent}
                </FixedSizeList>
            </div>
        );
    }
}
export default withStyles(styles)(VirtualizedList);