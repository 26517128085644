import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconBuild from '@material-ui/icons/BuildOutlined';
import { IObjects, IMe } from '../types';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            //display: 'flex',
            //alignItems: 'center',
            //width: 'calc(100% - 20px)',
            //margin: 'auto',
            backgroundColor:'transparent',
            borderWidth: 0, 
            borderColor: '#ccc',
            borderStyle: 'solid',
            borderRadius:'10px'
        },
        input01: {
            marginTop:5,
            marginBottom:5,
            marginLeft:5,
            marginRight:5,
            padding: 8,
            width:'80%',
            fontSize:'16px'
        },
        input02: {
            marginTop:10,
            marginBottom:10,
            padding: 8,
            width:'90%',
            fontSize:'16px'
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            borderBottomColor:'#BBBBBB',
        },
        dots:{
            display:'inline-block',
            marginLeft:10,
            width:'15px',
            height:'15px',
            borderRadius:'50%'
        }
    }),
);

interface IFormSettingsProps {
    item: IObjects;
    onChangeItem:Function;
    handleCalibrationClick:Function;
    me?:IMe;
}

export default function FormSettings(props: IFormSettingsProps) {
    const classes = useStyles();
    const {item, onChangeItem, me} = props;

    return (
        <div style={{height:'calc(50vh - 20px)', width:'100%'}}>
            <div style={{marginLeft:'auto', marginRight:'auto', textAlign:'center'}}>
                <input onChange={(evt:any)=>onChangeItem("description", evt)} className={classes.input02} defaultValue={item.description}/>
            </div>
            <div style={{width:'100%', overflowX:'auto'}} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{backgroundColor:'rgba(187, 187, 187, 0.3)'}} key={0}>
                            <TableCell size="small" align="left"><strong>{`Parámetro`}</strong></TableCell>
                            <TableCell size="small" align="center"><strong>{`Valores`}</strong></TableCell>
                            <TableCell size="small" align="center"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*<TableRow key={1}>
                            <TableCell className={classes.divider} align="left">{`Rango`}</TableCell>
                            <TableCell colSpan={2} className={classes.divider} align="center">{`1/1`}</TableCell>
    </TableRow>*/}
                        <TableRow key={2}>
                            <TableCell className={classes.divider} align="left">{`Aviso valor alto (>)`}</TableCell>
                            <TableCell className={classes.divider} align="center"><input onChange={(evt:any)=>onChangeItem("highValue", evt)} className={classes.input01} size={9} defaultValue={item.highValue}/></TableCell>
                            <TableCell className={classes.divider} align="center"><div className={classes.dots} style={{backgroundColor:'#E44A40'}}/></TableCell>
                        </TableRow>
                        <TableRow key={3}>
                        <TableCell className={classes.divider} align="left">{`Valor correcto`}</TableCell>
                        <TableCell className={classes.divider} align="center">
                            {`${item.highValue}/${item.lowValue}`}
                        </TableCell>
                        <TableCell className={classes.divider} align="center">
                            <div className={classes.dots} style={{backgroundColor:'#98CCA1'}}/>
                        </TableCell>
                        </TableRow>
                        <TableRow key={4}>
                            <TableCell className={classes.divider} align="left">{`Aviso valor bajo (<)`}</TableCell>
                            <TableCell className={classes.divider} align="center"><input onChange={(evt:any)=>onChangeItem("lowValue", evt)} className={classes.input01} size={9} defaultValue={item.lowValue}/></TableCell>
                            <TableCell className={classes.divider} align="center"><div className={classes.dots} style={{backgroundColor:'#A3D1F3'}}/></TableCell>
                        </TableRow>
                        <TableRow key={5}>
                        <TableCell className={classes.divider} align="left">{`No hay lectura`}</TableCell>
                        <TableCell className={classes.divider} align="center">
                            {`No aplica`}
                        </TableCell>
                        <TableCell className={classes.divider} align="center">
                            <div className={classes.dots} style={{backgroundColor:'#BBBBBB'}}/>
                        </TableCell>
                        </TableRow>
                        <TableRow key={6}>
                        <TableCell className={classes.divider} align="left">{`Crear alarma`}</TableCell>
                        <TableCell colSpan={2} className={classes.divider} align="center">
                            <Switch
                                checked={item.createAlarm}
                                onChange={(evt:any)=>onChangeItem("createAlarm", evt)}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </TableCell>
                        </TableRow>
                        <TableRow key={7}>
                        <TableCell className={classes.divider} align="left">{`Recibir mensaje`}</TableCell>
                        <TableCell colSpan={2} className={classes.divider} align="center">
                            <Switch
                                checked={item.sendMessage}
                                onChange={(evt:any)=>onChangeItem("sendMessage", evt)}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </TableCell>
                        </TableRow>
                        {(([1,2].indexOf(item.deviceTypeId) > -1 && me && [30,50,100].indexOf(me.role) > -1) || ([1,2].indexOf(item.deviceTypeId) > -1 && item.calibrate))?
                            <TableRow key={8}>
                                <TableCell className={classes.divider} align="left">{`Calibración`}</TableCell>
                                <TableCell className={classes.divider} align="center"><input type="number" onChange={(evt:any)=>onChangeItem("calibrationValue", evt)} className={classes.input01} size={9} defaultValue={item.calibrationValue}/></TableCell>
                                <TableCell className={classes.divider} align="center">
                                    <IconButton onClick={()=>props.handleCalibrationClick(item)} aria-label="settings">
                                        <IconBuild />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        :
                            null
                        }
                        <TableRow key={9}>
                            <TableCell className={classes.divider} align="left">{`ID`}</TableCell>
                            <TableCell colSpan={2} className={classes.divider} align="center">{`${item.dsCode}(${item.entityId})`}</TableCell>
                        </TableRow>
                        {item.referenceId?
                            <TableRow key={10}>
                                <TableCell className={classes.divider} align="left">{`ReferenceID`}</TableCell>
                                <TableCell colSpan={2} className={classes.divider} align="center">{`${item.referenceId}`}</TableCell>
                            </TableRow>
                        :
                            null
                        }

                    </TableBody>
                </Table>
            </div>
        </div>
    );
}