import React, { Component } from 'react';
//import { createStyles, Theme } from '@material-ui/core/styles';
import { IMe, IMarker, IObjects, IExploitation, IFilter } from '../types';
import CreateModal from './CreateDialog'
import Loader from './Loader';
import GoogleMaps from './GoogleMaps';
import {getColorMarker, searchMarker} from '../helpers';
import Layout from './Layout';
import {readLocalStorage, writeLocalStorage, isJson} from '../vc_general';
import IconButton from '@material-ui/core/IconButton';
import PartesIcon from '@material-ui/icons/LibraryBooks';
import FormFilters from './FormHomeFilters';
import { cloneDeep, filter } from 'lodash';
import LoaderBeauty from './Materials/activityProgress';

/*
const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});
*/
const dialogMarker=(marker:IMarker, itemView:Function, loading:boolean, itemOption:Function, loadedMarker?:IMarker)=>{

    return(
        <div style={{color:'#000', textAlign:'left', lineHeight:1.5}}>
            <strong>{`Id: `}</strong><label>{marker.farmId}</label>
            <br/>
            <strong>{`Granja: `}</strong><label>{marker.name}</label>
            <br/>
            <strong>{`Empresa: `}</strong><label>{marker.dataareaid}</label>
            <br/>

            <div style={{textAlign:'center'}}>
                {!loading?
                    (loadedMarker && loadedMarker.id == marker.id)?
                            null
                                :
                                    <button className="button-save" style={{marginTop:'15px', display:'inline'}} onClick={(e:any)=>itemView(marker, e)}>{`Visualizar`}</button>
                                        :
                                             <Loader loading={true}/>}
            </div>
            {/*<div style={{marginTop:10}}>
                <IconButton onClick={()=>itemOption(1, marker)} size="small" aria-label="partes">
                    <PartesIcon />
                </IconButton>
                </div>*/}

        </div>
    );
}

const GetSearchFarm=()=>{
    const value = readLocalStorage("searchFarm");

    if(value == null || value == undefined)
        return '';
    
    return value;
}

const GetFiltersMap=()=>{
    const value = readLocalStorage("mapfilters");

    if(value == null || value == undefined || !isJson(value))
        return undefined;
    
    return JSON.parse(value);
}

function calculateZoom(zoom:any) {
    if(zoom == undefined || zoom == null){
      let screenSize = window.innerWidth;
      if(screenSize >= 1500) {
        return 7;
      } else if(screenSize <1500 && screenSize > 700){
        return 6;
      } else if(screenSize <=700){
        return 5;
      } else {
        return 7;
      }
    }
    return Number(zoom);
}

function calculateCoords(coords:any) {
    if(coords == undefined || coords == null)
        return { lat: 40.29247249177379, lng: -3.980257712499995 }

    const data = JSON.parse(coords);
    return { lat: data.lat, lng: data.lng };
}

interface IHomeProps {
    myInfo?: IMe;
    history: any;
    match: any;
    version:string;
    markers:Array<IMarker>;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
    loading:boolean;
    loadingmap:boolean;
    loadMap:Function;
    loadExploitationData:Function;
    badgeAlarms:number;
    updateNinformed: Function;
    ninformed: boolean;
}

interface IHomeState {
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    location: ILocation;
    selectedMarker?:IMarker;
    searchValue:string;
    titleModal:string;
    closeButtonModal:boolean;
    filters?:IFilter;
    nsearch: number;
    issearching: boolean;
    //fmarkers:Array<IMarker>;
}

interface ILocation {
    coords:IILocation;
    zoom:number;
} 

interface IILocation {
    lat:number;
    lng:number;
} 

class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            location: {
                coords: calculateCoords(readLocalStorage('lastCoord')),
                zoom: calculateZoom(readLocalStorage('lastZoom')),
            },
            searchValue:GetSearchFarm(),
            titleModal:`MENSAJE`,
            closeButtonModal: false,
            filters: GetFiltersMap(),
            nsearch: 0,
            issearching: false
        };
    }

    componentDidMount(){
        this.props.loadMap();
        this.checkNews();
    }

    componentDidUpdate(prevprops:IHomeProps){
        if(prevprops.myInfo !== this.props.myInfo)
            this.checkNews();
    }

    checkNews = () => {
        const {myInfo, ninformed, updateNinformed} = this.props;
        if(!myInfo || ninformed)
            return;
        const nnews = filter(myInfo.news, function(o){return !o.viewed});

        if(nnews.length == 0)
            return;

        this.setState({
            creationModalOpen:true,
            modalType:2,
            typeAction: 1,
            titleModal: `NOTICIAS`,
            closeButtonModal: false,
            inputText: 'Hay nuevas funcionalidades en Smart Metrics. ¿Quiere verlas ahora?'
        }, ()=>updateNinformed());
    }

    manageSearchMarkers=(markers:Array<IMarker>, searchValue:string)=>{

        if(searchValue.length == 0)
            return markers;

        const searchedFarms = searchMarker(markers, searchValue);
        return searchedFarms;
    }

    handleRestoreIsSearch=()=>{
        setTimeout(() => {
            this.setState({issearching: false})
        }, 10);
    }

    handleSearchValueChange=(e:any)=>{
        const value = e.target.value;
        writeLocalStorage("searchFarm", value)
        this.setState({searchValue: value, issearching: true}, ()=>this.handleRestoreIsSearch());
    }

    handleOnKeydown=(e:any)=>{
        if(e.key == "Enter") {
            this.setState((prevState:IHomeState) => ({nsearch: prevState.nsearch + 1}));
        }
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleClick = (marker:IMarker, e:any) => {
        this.setState({ selectedMarker: marker });
    }

    handleCloseClick = (e:any) => {
        this.setState({ selectedMarker: undefined })
    }

    handleActionClick = (marker:IMarker, e:any) => {
        //this.props.handleNavBar(marker.exploitationId, marker.farmId, marker.dataareaid)

        this.props.loadExploitationData(marker);
    }

    handleOnChangeZoom=(zoom:number)=> {
        //console.log('dale mi amigo!!!!!', zoom)
        writeLocalStorage('lastZoom', zoom);
        //const nLocation = cloneDeep(this.state.location);
        //nLocation.zoom = zoom;
        //this.setState({location: nLocation});
    }

    handleOnChangeCoords=(coords:any)=> {
        //console.log('dale mi amigo!!!!!', coords)
        writeLocalStorage('lastCoord', JSON.stringify(coords.toJSON()));
        //const nLocation = cloneDeep(this.state.location);
        //nLocation.coords = coords.toJSON();
        //this.setState({location: nLocation});
    }

    renderMaps=(markers:Array<IMarker>)=>{
        return(
            <div style={{height:'calc(100vh - 160px)'}}>
                <GoogleMaps
                    id={["farmId","dataareaid"]}
                    infoWindow={null} 
                    location={this.state.location}
                    markerSelected={this.state.selectedMarker}
                    markers={markers}
                    mapsUrl={"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVovZOlpBtSodBhgQP8sWFR_iJP069r44&libraries=geometry,drawing,places"}
                    clusterSize={60}
                    onChangeZoom={this.handleOnChangeZoom}
                    onChangeCoords={this.handleOnChangeCoords}
                    defaultLocation={{
                        coords: calculateCoords(undefined),
                        zoom: calculateZoom(undefined),
                    }}
                    nsearch={this.state.nsearch}
                    issearching={this.state.issearching}
                />
            </div>
        );
        /*else {
            return(
                <div style={{marginLeft:'50%',marginTop:'30%'}}>
                    <Loader loading={true}/>
                </div>
                );
        }*/
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica ir a las noticias
                this.props.history.push('/news');
                break;
            default:
                break;
        }
    }

    handleFilterClick=()=>{
        this.setState({
            creationModalOpen:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `FILTROS MAPA`,
            closeButtonModal: true,
            inputText: <FormFilters cFilters={this.state.filters} handleApplyFilterClick={this.handleApplyFilterClick} />
        });
        /*const nLocation = cloneDeep(this.state.location);
        nLocation.coords = { lat: 40.29247249177379, lng: -3.980257712499995 };
        nLocation.zoom = 6;
        this.setState({location:nLocation})*/
    }

    handleApplyFilterClick = (filter:IFilter) => {
        writeLocalStorage("mapfilters", JSON.stringify(filter));

        this.setState((prevState:IHomeState) => ({creationModalOpen:false, filters: filter, nsearch: prevState.nsearch + 1, issearching: true}),()=>this.handleRestoreIsSearch());
        //this.setState({creationModalOpen:false, filters: filter});
        //console.log(filter)
    }

    handleItemOption=(action:number, marker:IMarker)=>{
        switch(action){
            case 1: // Ir a los partes de la granja
                this.props.history.push(`/${marker.dataareaid}/${marker.farmId}/partes`);
                break;
        }
    }

    applyFilters = (markers:Array<IMarker>) => {
        const {filters, searchValue} = this.state;

        if(filters && filters.onlyAlarms)
            markers = filter(markers, function(o){return o.priority !== 0});

        if(filters && filters.tipo > -1)
            markers = filter(markers, function(o){return o.tipo == filters.tipo});

        if(filters && filters.tipoDevice > -1)
            markers = filter(markers, function(o){ 
                if(filters.tipoDevice == 1) // Ambiente
                    return (o.groupDevices !== null && isJson(o.groupDevices) && JSON.parse(o.groupDevices).ambiente == 1);

                else if(filters.tipoDevice == 2) // Consumo agua
                    return (o.groupDevices !== null && isJson(o.groupDevices) && JSON.parse(o.groupDevices).agua == 1);

                else if(filters.tipoDevice == 3) // Silos
                    return (o.groupDevices !== null && isJson(o.groupDevices) && JSON.parse(o.groupDevices).silos == 1);   
                return o.id == o.id;
            });

        markers = this.manageSearchMarkers(markers, searchValue).map((marker:IMarker, index:number)=>{
            return {
                ...marker,
                dialogBox:dialogMarker(marker, this.handleActionClick, this.props.loading, this.handleItemOption, this.props.loadedMarker),
                icon:getColorMarker(marker.priority, marker.tipo),
                handleClick:this.handleClick,
                handleCloseClick:this.handleCloseClick,
            };
        })
        return markers;
    }

    render() {
        //console.log('render')
        const {farmData, loading, badgeAlarms, markers, loadingmap, myInfo} = this.props;

        return (
            <div>
            <Layout me={myInfo} marker={this.props.loadedMarker} history={this.props.history} handleFilterClick={this.handleFilterClick} onItemClick={(e:any,item:any, type:number)=>this.handleOnDrawerItemClick(e, item, type)} onSearchChange={(evt:any)=>this.handleSearchValueChange(evt)} onKeydown={(e:any)=>this.handleOnKeydown(e)} searchValue={this.state.searchValue} placeholderSearch={"Buscar granja..."} badgeAlarms={badgeAlarms} navBar={farmData?.data.navbar}/>
                <div className='home-container'>

                    <div className='home-content'>

                        {this.renderMaps(this.applyFilters(markers))}
                        
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.inputText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.typeAction}
                                loadingModal={false}
                                titleModal={this.state.titleModal}
                                closeButton={this.state.closeButtonModal}
                            />
                        :
                            null
                        }
                    </div>
                </div>
                <LoaderBeauty visible={loadingmap}/>
            </div>
        );
    }
}

export default Home;