import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IMarker, IMe, IObjects, IVDevice } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import CreateModal from './CreateDialog'
import OrderService from '../ApiClients/OrderService';

const DeviceForm = {referenceId: ``, farmId: ``, code: -1, codeDsc: ``, farmName: ``, dataareaid: ``};

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

interface IFindDeviceProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface IFindDeviceState {
    register:IVDevice;
    device?: IVDevice;
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    loadingModal:boolean;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    loadingFindDevice: boolean;
}

class FindDevice extends Component<IFindDeviceProps, IFindDeviceState> {
    constructor(props: IFindDeviceProps) {
        super(props);
        this.state = {
            register: DeviceForm,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            loadingModal: false,
            loadingFindDevice: false
        };
    }

    FindDevice=async(register:IVDevice)=> {
        const response = await OrderService.FindDevice(register.referenceId);
        let resetForm = false;

        let text = `¡ATENCIÓN! No se ha podido realizar dicha acción.`
        if(response) {
            switch(response.code){
                case 1:
                    text = `Código: ${response.farmId} - Nombre: ${response.farmName}`
                    resetForm = true;
                    break;
                case 0:
                    text = `${response.codeDsc}`
                    break;
                case -1:
                    text = `${response.codeDsc}`
                    break;
            }    
        }
        
        /*this.setState({
            modalType: 1,
            titleModal: `MENSAJE`,
            closeButtonModal: true,
            inputText: `${text}`,
            typeAction: 1,
            creationModalOpen: true,
            loadingModal: false,
        })*/

        this.setState({
            device: response,
            loadingFindDevice: false
        })
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                break;
            default:
                break;
        }
    }

    handleChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);
        newRegister[id] = evt.target.value;
        this.setState({register:newRegister});
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleFindDeviceClick = (register: IVDevice) => {
        /*this.setState({
            modalType: 2,
            titleModal: `MENSAJE`,
            closeButtonModal: true,
            inputText: `Buscando dispositivo con referencia ${register.dscode}...`,
            typeAction: 1,
            creationModalOpen: true,
            loadingModal: true,
        }, ()=>this.FindDevice(register))*/
        this.setState({
            loadingFindDevice: true
        }, ()=>this.FindDevice(register))
    }

    FindControl = (register:IVDevice) => {
        if(register.referenceId.length == 0)
            return false;

        return true;
    }

    GetFindDeviceInformation = (device:IVDevice) => {
        switch(device.code){
            case -1: // Doesn't exist
                return(
                    <div>
                        <strong style={{fontSize:'15px', color:'#E44A40'}}>{`${device.codeDsc}`}</strong>
                    </div>
                );
            case 0: // Exists but is not linked
                return(
                    <div>
                        <strong style={{fontSize:'15px', color:'#F07937'}}>{`${device.codeDsc}`}</strong>
                    </div>
                );
            case 1: // Exists and is linked
                return(
                    <div>
                        <div>
                            <strong style={{fontSize:'15px', color:'#98CCA1'}}>{`Dispositivo encontrado`}</strong>
                        </div>
                        <table id='t_find_device'>
                            <tbody>
                                <tr>
                                    <th style={{textAlign:'left'}}>{`Código`}</th>
                                    <td style={{textAlign:'center'}}>{device.referenceId}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'left'}}>{`Empresa`}</th>
                                    <td style={{textAlign:'center'}}>{device.dataareaid}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'left'}}>{`Explotación`}</th>
                                    <td style={{textAlign:'center'}}>{device.farmId}</td>
                                </tr>
                                <tr>
                                    <th style={{textAlign:'left'}}>{`Nombre`}</th>
                                    <td style={{textAlign:'center'}}>{device.farmName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            default:
                return(
                    <div>
                        <strong style={{fontSize:'18px', color:'#E44A40'}}>{`Sin datos que mostrar`}</strong>
                        <a>{`Ha habido un problema, vuelva a intentarlo más tarde.`}</a>
                    </div>
                );
        }

    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {register, device, loadingFindDevice} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {myInfo?
                            [50,100].indexOf(myInfo.role) > -1?
                                <div style={{marginTop:10, marginLeft:10}}>
                                    <div style={{marginBottom:20}}>
                                        <strong style={{fontSize:'18px'}}>{`Encuentra tu dispositivo`}</strong>
                                        <p> {`Aquí puedes encontrar a qué explotación está vinculado tu dispositivo.`}</p>
                                    </div>
                                    <div>
                                        <table style={{width:'100%', maxWidth:'400px'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TextFieldInput
                                                            key={0}
                                                            name={`Código dispositivo`}
                                                            id={`referenceId`}
                                                            fieldsData={register}
                                                            handleFieldChange={this.handleChangeUser}
                                                            size="medium"
                                                            shrink={true}
                                                            type={"text"}
                                                            required={true}
                                                            style={3}
                                                            helper={``}
                                                            maxText={15}
                                                            noError={true}
                                                            disabled={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} style={{fontSize:'11px'}}>
                                                        {this.FindControl(register)?
                                                            loadingFindDevice?
                                                                <Loader loading={true}/>
                                                            :
                                                                <button onClick={()=>this.handleFindDeviceClick(register)} className={classes.buttonSave}>{`Buscar`}</button>
                                                        :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        
                                            {device?
                                                <div style={{marginTop:20}}>
                                                    {this.GetFindDeviceInformation(device)}
                                                </div>
                                            :
                                                null
                                            }
                                    </div>
                                    {this.state.creationModalOpen?
                                        <CreateModal
                                            onCloseCreationModal={this.onCloseCreationModal}
                                            isOpen={this.state.creationModalOpen}
                                            inputText={this.state.inputText}
                                            typeModal={this.state.modalType}
                                            action={this.handleCreateModal}
                                            typeAction={this.state.typeAction}
                                            loadingModal={this.state.loadingModal}
                                            titleModal={this.state.titleModal}
                                            closeButton={this.state.closeButtonModal}
                                            fullscreen={this.state.fullScreenModal}
                                            maxwidth={this.state.maxwidthModal}
                                        />
                                    :
                                        null
                                    }
                                </div>
                            :
                                `No tiene permisos para visualizar esta sección.`
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(FindDevice);