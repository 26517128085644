import { fetchAPI, zLibInflate } from '../helpers';
import appData from './appData'
import { IMarker, IMe } from '../types';
import { filter } from 'lodash';

const baseURL = 'https://fnc-metrics-01.azurewebsites.net/api/';

class OrderService {

    static createDataFarmBlob = async () => {
        try {
          let url = `${baseURL}CreateDataFarm`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return "ERROR";
        }
      }

      static loadMap = async () => {
        try {
          let url = `${baseURL}LoadExploitations`;
          let map = appData.getMap();

          if(map == undefined){
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', true, undefined, false);

            if(response.data !== null){
              let nData = zLibInflate(response.data);
              map = nData;
              appData.setMap(nData);

              let finalDate = new Date().getTime();
              let diff = finalDate - originalDate;
            }
        }
          //console.log(map)
          return map;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
      static getMarker = async (entityFarmId:number,entityDataareaid:number) => {
        if(appData.getMap() === undefined){
          await OrderService.loadMap()
        }

        let marker = appData.getMap()?.find((item:IMarker) => {
            return item.entityFarmId == entityFarmId && item.entityDataareaid == entityDataareaid;
        });
        return marker;
      }

      static LoadObjects = async (marker:IMarker, refresh:boolean) => {
        try {
          let url = `${baseURL}LoadObjects?entityFarmId=${marker.entityFarmId}&entityDataareaid=${marker.entityDataareaid}`
          let sObjects = appData.getExploitationData()?.data;

          if(!sObjects?.dashboards || refresh){
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', true, undefined, false);

            if(response.data !== null){
              sObjects = zLibInflate(response.data);
              //appData.setExploitationData(farm);
              //sObjects = {navbar:objects.navbar, dashboards:objects.dashboard};
              let finalDate = new Date().getTime();
              let diff = finalDate - originalDate;
            }
          }
          //console.log(sObjects)
          return sObjects;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }
      
      static LoadAlarms = async (marker:IMarker, typeList:number, refresh:boolean) => {
        try {
          let url = `${baseURL}LoadAlarms?tipo=${typeList}&entityFarmId=${marker.entityFarmId}&entityDataareaid=${marker.entityDataareaid}`
          let alarms:any = typeList == 0?appData.getExploitationData()?.data.alarmsA:appData.getExploitationData()?.data.alarmsH;

          if(!alarms || refresh){
            let response = await fetchAPI(url, 'GET', true, undefined, false);

            if(response.data !== null){
              alarms = zLibInflate(response.data);
              let finalDate = new Date().getTime();
            }
          }
          //console.log(alarms)
          return alarms;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadBusiness = async () => {
          try {
            let url = `${baseURL}LoadBusiness`
  
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            if(response.data !== null){
              const data = zLibInflate(response.data);
              let finalDate = new Date().getTime();
              let diff = finalDate - originalDate;
              
              return data;
            }
  
            return [];
          } catch (err) {
              console.log(err);
              return undefined;
          }
      }
      /*static loadDashboard = async (farmId:string,dataareaid:string, dashboard:number) => {
        try {
          let dashboards = appData.getExploitationData()?.data.dashboards;

          if(dashboards == undefined){
            let response = await OrderService.loadExploitation(farmId,dataareaid, true);

            if(response){
              dashboards = response.data.dashboards;
            }
          }
          
          let nDashboard = filter(dashboards, function(o){return o.parentId == dashboard});

          return nDashboard;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }*/



      static loadAvailableDevices = async (entityDataareaid:number, entityFarmId:number) => {
        try {
          let url = `${baseURL}LoadAvailableDevices?entityDataareaid=${entityDataareaid}&entityFarmId=${entityFarmId}`

          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          if(response.data !== null){
            const data = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            
            return data;
          }

          return [];
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static FindDevice = async (referenceId:string) => {
        try {
          let url = `${baseURL}FindDevice?referenceId=${referenceId}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          if(response.data !== null){
            const data = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            
            return data;
          }

          return [];
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static loadDataBlob = async (farmId:string, dataareaid:string, refresh:boolean) => {
        try {
          //let url = `${baseURL}stgLoadData?exploitationId=${exploitationId}`
          let data = appData.getOnlineValues();

          if(data == undefined || refresh){
            let url = `https://stgsmartfarmgvc01.blob.core.windows.net/smartmetrics/data/DataFarm01/DataFarm_${farmId}${dataareaid}.sm`;
            //let url = `https://controlgranjas.blob.core.windows.net/data-app/DataFarm_${exploitationId}.json?sv=2018-03-28&si=data-app-16A1CF80CB3&sr=c&sig=IRXR5t%2FTAbhR6XPy6FMMnzFMHdyVIIVIUxl0GsBNgms%3D`
            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', false, undefined, true);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            let nData = zLibInflate(response);
            appData.setOnlineValues(nData);
            data = nData
            /*setTimeout(() => {
              return nData
              appData.setOnlineValues(nData);
              appData.setHistoricoValues(nData);
            }, 10000);*/

          }

          return data

        } catch (err) {
            console.log(err);
            appData.setOnlineValues([]);
            appData.setHistoricoValues([]);
            return [];
        }
      }

      static loadDataBlob02 = async (dataareaid:string, farmId:string) => {
        try {
          //let url = `${baseURL}stgLoadData?exploitationId=${exploitationId}`
          let data = undefined;

          if(data == undefined){
            let url = `https://stgsmartfarmgvc01.blob.core.windows.net/smartmetrics/data/DataFarm02/DataFarm_${farmId}${dataareaid}.sm`;

            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', false, undefined, true);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            let nData = zLibInflate(response);
            data = nData;
          }

          //console.log(data);
          return data

        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static loadDataBlob03 = async (dataareaid:string, farmId:string) => {
        try {
          //let url = `${baseURL}stgLoadData?exploitationId=${exploitationId}`
          let data = undefined;

          if(data == undefined){
            let url = `https://stgsmartfarmgvc01.blob.core.windows.net/smartmetrics/data/DataFarm03/DataFarm_${farmId}${dataareaid}.sm`;

            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', false, undefined, true);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            let nData = zLibInflate(response);
            data = nData;
          }

          //console.log(data);
          return data

        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static loadDataBlob04 = async (dataareaid:string, farmId:string) => {
        try {
          //let url = `${baseURL}stgLoadData?exploitationId=${exploitationId}`
          let data = undefined;

          if(data == undefined){
            let url = `https://stgsmartfarmgvc01.blob.core.windows.net/smartmetrics/data/DataFarm04/DataFarm_${farmId}${dataareaid}.sm`;

            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', false, undefined, true);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            let nData = zLibInflate(response);
            data = nData;
          }

          //console.log(data);
          return data

        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static loadDataBlob05 = async (dataareaid:string, farmId:string) => {
        try {
          //let url = `${baseURL}stgLoadData?exploitationId=${exploitationId}`
          let data = undefined;

          if(data == undefined){
            let url = `https://stgsmartfarmgvc01.blob.core.windows.net/smartmetrics/data/DataFarm05/DataFarm_${farmId}${dataareaid}.sm`;

            let originalDate = new Date().getTime();
            let response = await fetchAPI(url, 'GET', false, undefined, true);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            let nData = zLibInflate(response);
            data = nData;
          }

          //console.log(data);
          return data

        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      /*static loadHistoricoData = async (listId, startDay:string, endDay:string, dataType:number) => {
        try {
          let url = `${baseURL}ReturnValues?startDay=${startDay}&endDay=${endDay}&dataType=${dataType}`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'POST', true, {...listId}, false);
          let finalDate = new Date().getTime();
          let diff = finalDate - originalDate;
          //console.log('loadHistoricoData', `${response.length} items loaded in ${diff} ms`)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }*/
  
      /*static loadDeviceList = async (farmId:string, dataareaid:string) => {
        try {
          let url = `${baseURL}LoadDeviceList?farmId=${farmId}&dataareaid=${dataareaid}`
          console.log(url)
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          let finalDate = new Date().getTime();
          let diff = finalDate - originalDate;
          //console.log('loadDeviceList', `${response.length} items loaded in ${diff} ms`)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }*/
  
      /*static updateAlarms = async (updatedData) => {
        try {
          //console.log('updatedData', updatedData)
          let url = `${baseURL}UpdateAlarms`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      static updateNews = async (updatedData) => {
        try {
          //console.log('updatedData', updatedData)
          let url = `${baseURL}UpdateNews`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return -1;
        }
      }
  
      static updateLimitsCfg = async (updatedData) => {
        try {
          let url = `${baseURL}UpdateCfg`
          let response = await fetchAPI(url, 'POST', true, {...updatedData}, false);
          //console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return {response:0, msg:'Error en frontend'};
        }
      }*/
  
      static me = async () => {
        try {
          let url = `${baseURL}me`
          let originalDate = new Date().getTime();
          let response = await fetchAPI(url, 'GET', true, undefined, false);

          if(response.data !== null && response !== undefined){
            let nData = zLibInflate(response.data);
            let finalDate = new Date().getTime();
            let diff = finalDate - originalDate;
            //console.log('me', nData)
            //console.log('me', nData)
            return nData;
          }
          return undefined;

        } catch (err) {
            console.log(err);
            return undefined;
        }
      }
  
      static saveData = async (data:any, action:number) => {
        try {
          let url = `${baseURL}saveData?action=${action}`
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static downloadData = async (data:any) => {
        try {
          let url = `${baseURL}DownloadData`
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          //console.log(response)
          if(response.data !== null){
            return zLibInflate(response.data);
          }
          return undefined;
        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static LoadInvitations = async () => {
        try {
          let url = `${baseURL}LoadInvitations`
          let response = await fetchAPI(url, 'get', true, undefined, false);
          //console.log(response)
          if(response.data !== null){
            return zLibInflate(response.data);
          }
          return undefined;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      static metricService = async (metricType:number) => { // Llamadas de registro de métricas //
        try {
            const origin = {origin:window.location.href}
            let url = `${baseURL}metrics?metricType=${metricType}`
            await fetchAPI(url, 'PUT', true, {...origin}, false);
        } catch(err) {
  
        }
      }
}
export default OrderService;