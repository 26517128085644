import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IMarker, IMe, IObjects, IIRegister, IBusiness } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import { SelectorField, CheckBoxField, TextFieldInput, SwitchField } from './FormsWidgets';
import CreateModal from './CreateDialog'
import OrderService from '../ApiClients/OrderService';
import {validateEmail, getNumeric, GetDatetimeFormat} from '../vc_general';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function verifyPhone(phone:string){
    let newPhone = getNumeric(phone);
    if(newPhone.length == 11)
        return true;
    return false;
}

const UserForm = {name: ``, surname: ``, phone: ``, email: ``, farmId: ``, dataareaid: ``, exists: false, autoinvitation: false, nealia: true};

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

interface IRegisterUserProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface IRegisterUserState {
    register:IIRegister;
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    loadingModal:boolean;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    historicinv?: Array<IIRegister>;
    historicopen:boolean;
    business: Array<IBusiness>;
    loading:boolean;
}

class Notificaciones extends Component<IRegisterUserProps, IRegisterUserState> {
    constructor(props: IRegisterUserProps) {
        super(props);
        this.state = {
            register: UserForm,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            loadingModal: false,
            historicopen: false,
            business: [],
            loading: true
        };
    }

    componentDidMount(){
        this.loadBusiness();
        this.LoadInvitations();
    }

    LoadInvitations=async()=>{
        let ret:any = await OrderService.LoadInvitations();

        this.setState({historicinv:ret});
        if(this.state.historicopen)
            this.handleHistoricInvClick()
    }

    loadBusiness=async()=>{
        let business:Array<IBusiness> = await OrderService.LoadBusiness()
        if(!business){
            this.setState({business:[], loading: false});
        } else {
            this.setState({business:business, loading: false});
        }
    }

    RegisterUser=async(register:IIRegister)=> {
        const response = await OrderService.saveData(register, 8);
        let resetForm = false;
        let text = `¡ATENCIÓN! No se ha podido realizar dicha acción.`
        switch(response){
            case 1:
                text = `Usuario registrado correctamente, en un plazo de 24h se aplicarán los cambios.`
                resetForm = true;
                this.LoadInvitations();
                break;
            case 2:
                text = `¡ATENCIÓN! El usuario ya tiene registrada la granja.`
                break;
            case 3:
                text = `¡ATENCIÓN! La granja no existe.`
                break;
            case 4:
                text = `¡ATENCIÓN! No tienes permisos para invitar a esa granja.`
                break;
            case 5:
                text = `¡ATENCIÓN! No estás registrado en la plataforma SmartMetrics.`
                break;
        }
        this.setState({creationModalOpen:true, modalType: 1, loadingModal: false, register: resetForm?UserForm:register, inputText: text })
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, historicopen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica registrar un nuevo dispositivo
                let newRegister = cloneDeep(this.state.register);
                if(newRegister.autoinvitation)
                    newRegister.email = this.props.myInfo?this.props.myInfo.usuario:'unknow';  
                this.setState({loadingModal: true}, ()=>this.RegisterUser(newRegister));
                break;
            default:
                break;
        }
    }

    handleChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);
        newRegister[id] = evt.target.value;
        this.setState({register:newRegister});
    }

    handleSwichChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);
        newRegister[id] = evt.target.checked;
        this.setState({register:newRegister});
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleRegisterDeviceClick = (register: IIRegister) => {

        let txt = `¡ATENCIÓN! ¿Quieres invitar al usuario "${register.email}" en la granja "${register.farmId}" de la empresa "${register.dataareaid}"?`;

        if(register.autoinvitation)
            txt = `¡ATENCIÓN! ¿Quieres solicitar permiso para la granja "${register.farmId}" de la empresa "${register.dataareaid}"?`;
        else if(register.exists)
            txt = `¡ATENCIÓN! ¿Quieres dar permisos al usuario "${register.email}" en la granja "${register.farmId}" de la empresa "${register.dataareaid}"?`;

        this.setState({
            modalType: 2,
            titleModal: `MENSAJE`,
            closeButtonModal: false,
            inputText: txt,
            typeAction: 1,
            creationModalOpen: true
        })
    }

    RegisterControl = (register:IIRegister) => {
        if(register.name.length == 0 && !register.exists && !register.autoinvitation)
            return false;
        if(register.surname.length == 0 && !register.exists && !register.autoinvitation)
            return false;
        if(register.farmId.length == 0)
            return false;
        if(register.dataareaid.length == 0)
            return false;
        if(!validateEmail(register.email) && !register.autoinvitation)
            return false;
        if(!verifyPhone(register.phone) && !register.exists && !register.autoinvitation)
            return false;
        return true;
    }

    handleHistoricInvClick = () => {
        const {historicinv} = this.state;
        let txt:any = `Cargando histórico invitaciones...`;

        if(historicinv){
            if(historicinv.length == 0)
                txt = `Aún no ha realizado ninguna invitación`;
            else {
                const rows = historicinv.map((inv:IIRegister, index:number)=>{
                    return(
                        <TableRow key={index}>
                            <TableCell align="center">{inv.id}</TableCell>
                            <TableCell align="center">{inv.email}</TableCell>
                            <TableCell align="center">{inv.farmId}</TableCell>
                            <TableCell align="center">{inv.dataareaid}</TableCell>
                            <TableCell align="center">{GetDatetimeFormat(inv.dt_created)}</TableCell>
                            <TableCell align="center">{inv.result == null?`Pendiente`:inv.result}</TableCell>
                            <TableCell align="center">{inv.dt_processed == null?``:GetDatetimeFormat(inv.dt_processed)}</TableCell>
                        </TableRow>
                    );
                });
                txt = <div style={{width:'100%', height:'80vh', overflowX:'auto', overflowY:'auto'}} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{backgroundColor:'rgba(0, 43, 97,0.8)'}} key={0}>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`ID`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Usuario`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Granja`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Empresa`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Fecha invitación`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Estado`}</strong></TableCell>
                            <TableCell style={{color:'#fff'}} size="small" align="center"><strong>{`Fecha procesado`}</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </div>
            }
        }

        this.setState({
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `Historial invitaciones`,
            inputText: txt,
            fullScreenModal:false,
            historicopen: true
        });
    };

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {register} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {myInfo && !this.state.loading?
                            [50,100].indexOf(myInfo.role) > -1?
                                <div style={{marginTop:10, marginLeft:10}}>
                                    <div style={{marginBottom:20}}>
                                        <div style={{display:'inline'}}>
                                            <strong style={{fontSize:'18px'}}>{`Invitar a usuarios`}</strong>
                                            <IconButton onClick={()=>this.handleHistoricInvClick()}  style={{marginLeft:5}} size="medium" aria-label="Usuario">
                                                <HistoryIcon />
                                            </IconButton>
                                        </div>
                                        <p> {`Aquí puedes invitar a otros usuarios a ver las granjas que tienes configuradas o pedir permiso para ver otras granjas.`}</p>
                                    </div>
                                    <div>
                                        <table style={{width:'100%', maxWidth:'400px'}}>
                                            
                                            {[50,100].indexOf(myInfo.role) > -1?
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <SwitchField
                                                                key={0}
                                                                name={`autoinvitation`}
                                                                id={`autoinvitation`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleSwichChangeUser}
                                                                disabled={false}
                                                                color="secondary"
                                                            />
                                                            {`Auto invitar`}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            :
                                                null    
                                            }
                                            {!register.autoinvitation?
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <SwitchField
                                                                key={0}
                                                                name={`exists`}
                                                                id={`exists`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleSwichChangeUser}
                                                                disabled={false}
                                                            />
                                                            {`Usuario existente`}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            :    
                                                null
                                            }
                                            {!register.exists && !register.autoinvitation?
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Nombre usuario`}
                                                                id={`name`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={3}
                                                                helper={``}
                                                                maxText={50}
                                                                noError={true}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Apellido usuario`}
                                                                id={`surname`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={3}
                                                                helper={``}
                                                                maxText={50}
                                                                noError={true}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Número de teléfono`}
                                                                id={`phone`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={3}
                                                                helper={``}
                                                                maxText={11}
                                                                noError={verifyPhone(register.phone)}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} style={{fontSize:'11px'}}>
                                                            {`Ej: 34 XXX XXX XXX`}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            :
                                                null
                                            }
                                            {!register.autoinvitation?
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Email`}
                                                                id={`email`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={3}
                                                                helper={``}
                                                                maxText={50}
                                                                noError={validateEmail(register.email)}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            :
                                                null    
                                            }
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TextFieldInput
                                                            key={0}
                                                            name={`Código granja`}
                                                            id={`farmId`}
                                                            fieldsData={register}
                                                            handleFieldChange={this.handleChangeUser}
                                                            size="medium"
                                                            shrink={true}
                                                            type={"text"}
                                                            required={true}
                                                            style={2}
                                                            helper={``}
                                                            maxText={8}
                                                            noError={true}
                                                            disabled={false}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{paddingTop: '10px'}}>
                                                        <SelectorField
                                                            key={0}
                                                            name={`Empresa`}
                                                            id={`dataareaid`}
                                                            fieldsData={this.state.business}
                                                            handleFieldChange={this.handleChangeUser}
                                                            required={true}
                                                            style={1}
                                                            deviceData={register}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} style={{fontSize:'11px'}}>
                                                        {this.RegisterControl(register)?
                                                            <button onClick={()=>this.handleRegisterDeviceClick(register)} className={classes.buttonSave}>{`Registrar`}</button>
                                                        :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.creationModalOpen?
                                        <CreateModal
                                            onCloseCreationModal={this.onCloseCreationModal}
                                            isOpen={this.state.creationModalOpen}
                                            inputText={this.state.inputText}
                                            typeModal={this.state.modalType}
                                            action={this.handleCreateModal}
                                            typeAction={this.state.typeAction}
                                            loadingModal={this.state.loadingModal}
                                            titleModal={this.state.titleModal}
                                            closeButton={this.state.closeButtonModal}
                                            fullscreen={this.state.fullScreenModal}
                                            maxwidth={this.state.maxwidthModal}
                                        />
                                    :
                                        null
                                    }
                                </div>
                            :
                                `No tiene permisos para visualizar la sección de invitación a usuarios.`
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);