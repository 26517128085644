import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon01 from '../Assets/SmartMetrics_menu01.png';
import MenuIcon02 from '../Assets/SmartMetrics_menu02.png';
import NestedList from './NestedList';
import {NewInterface} from '../ApiClients/DummyData';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerPaper: {
    position: 'absolute',
    top:143,
    backgroundColor:'#003B71',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IDrawerProps {
    opened: boolean;
    onItemClick: any;
    anchor:Anchor;
    onToggle:any;
    navBar:any;
}

export default function TemporaryDrawer(props: IDrawerProps) {
  const classes = useStyles();

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    props.onToggle();
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      //onClick={props.onToggle}
      onKeyDown={props.onToggle}
    >
        <NestedList items={props.navBar} onClick={props.onItemClick}/>

        {/*props.navBar.map((item:any, index:number) => (
          <List key={item.id}>
            <ListItem button key={item.id}>
              <ListItemIcon onClick={()=>props.onItemClick(item.route)}><img src={MenuIcon01} height={25} /></ListItemIcon>
              <ListItemText onClick={()=>props.onItemClick(item.route)} style={{color:'#fff'}} primary={item.name} />
            </ListItem>
            <Divider style={{backgroundColor:'#fff'}} />
          </List>
        ))*/}
    </div>
  );

  return (
    <div>
        <React.Fragment key={props.anchor}>
          <Drawer classes={{
            paper: classes.drawerPaper
            }} 
            anchor={props.anchor} open={props.opened} onClose={toggleDrawer(props.anchor, false)}>
            {list(props.anchor)}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
