import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconBuild from '@material-ui/icons/BuildOutlined';
import { IObjects, IMe } from '../types';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import NestedList from './NestedListDashboard';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            //display: 'flex',
            //alignItems: 'center',
            //width: 'calc(100% - 20px)',
            //margin: 'auto',
            backgroundColor:'transparent',
            borderWidth: 0, 
            borderColor: '#ccc',
            borderStyle: 'solid',
            borderRadius:'10px'
        },
        input01: {
            marginTop:5,
            marginBottom:5,
            marginLeft:5,
            marginRight:5,
            padding: 8,
            width:'80%',
            fontSize:'16px'
        },
        input02: {
            marginTop:10,
            marginBottom:10,
            padding: 8,
            width:'90%',
            fontSize:'16px'
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            borderBottomColor:'#BBBBBB',
        },
        dots:{
            display:'inline-block',
            marginLeft:10,
            width:'15px',
            height:'15px',
            borderRadius:'50%'
        }
    }),
);

interface IFormReorderProps {
    items: Array<IObjects>;
    onPinItem:Function;
    me?:IMe;
}

export default function FormReorder(props: IFormReorderProps) {
    const classes = useStyles();
    const {items, onPinItem, me} = props;
    const [offset, setoffset] = React.useState<boolean>(false);

    return (
        <div style={{height:'calc(50vh - 20px)', width:'100%'}}>
            <div style={{position:'absolute', bottom:50, top: 50, overflowY: 'auto'}}>
                <NestedList items={items} onClick={onPinItem}/>
            </div>
            <div style={{position:'absolute',padding:10, bottom:0, width:'90%'}} >
                <FormControlLabel
                    control={
                        <Switch
                            checked={offset}
                            onChange={(evt:any)=>setoffset(evt.target.checked)}
                            color="secondary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Arriba"
                />
            </div>
        </div>
    );
}