import React from 'react';
import './activityProgress.css';
import loaderImage from '../../Assets/SmartMetrics_icon.png';

/* eslint-disable react/prefer-stateless-function */
type ActivityProgressState = {
}
type ActivityProgressProps = {
    visible: Boolean
}
// Només perque vegis com fer una animació simple de càrrega
class ActivityProgress extends React.Component<ActivityProgressProps, ActivityProgressState> {
    constructor(props:ActivityProgressProps) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div
                className={`loader ${this.props.visible ? '' : 'loaderHidden'
                    }`}
            >
                <img
                    className={'logo'}
                    src={`${loaderImage}`}
                />
            </div>
        );
    }
}

export default ActivityProgress;
