import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe } from '../types';

const styles = (theme: Theme) => createStyles({
    containerStyle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#888',
        lineHeight: '1.2',
        marginTop: '40vh',
    },
    titleStyle: {
        color: '#555',
        fontSize: '2em',
        fontWeight: 'bold',
    },
    bodyStyle: {
        margin: '0 auto',
        width: '280px',
    }
});

interface IUnauthorizedProps {
    history: any;
    classes: any;
    refreshMyInfo: Function;
    myInfo?: IMe;
}

interface IUnauthorizedState {
}

let timer : any;

class Unauthorized extends Component<IUnauthorizedProps, IUnauthorizedState> {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.containerStyle}>
                <div className={classes.titleStyle}>{'NO AUTORIZADO'}</div>
                <p className={classes.bodyStyle}>{'Lo sentimos, pero no tiene permisos para interactuar con la web.'}</p>
                <br />
            {/*<button onClick={this.goBack}>{'Volver a intentar.'}</button>*/}
            </div>
        );
    }
}

export default withStyles(styles)(Unauthorized);