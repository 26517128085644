import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, IObjects, IExploitation, IData, IIDevice, IDevice, IBattery, IMarker, IIIDevice } from '../types';
import CreateModal from './CreateDialog'
import {searchObject} from '../helpers';
import OrderService from '../ApiClients/OrderService';
import Layout from './Layout';
import {Widget04} from './Widgets';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormSetting from './FormSettings';
import FormBattery from './FormBattery';
import {cloneDeep, find, findIndex} from 'lodash';
import { Bar, Line } from 'react-chartjs-2';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ImportDeviceIcon from '@material-ui/icons/SystemUpdateOutlined';
import {SelectorField, TextFieldInput, CheckBoxField} from './FormsWidgets';
import Loader from './Loader';
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import LoaderBeauty from './Materials/activityProgress';
import FormReorder from './FormReorder';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
        nested: {
        paddingLeft: theme.spacing(4),
    },
});

function GetComponent(list:IObjects, index:number, handleSettingsClick:Function, handleChartClick:Function, handleDeleteClick:Function, handleBatteryClick:Function, handleObjectReorder:Function, state:IDashboardState, dataDevices?:Array<IData>){
    switch(list.objectType){
        default:
            return(<Widget04 key={index} pObject={list} dataDevices={dataDevices} handleSettingsClick={handleSettingsClick} handleChartClick={handleChartClick} handleDeleteClick={handleDeleteClick} handleBatteryClick={handleBatteryClick} handleObjectReorder={handleObjectReorder} editing={state.editing}/>);
    }
}

function GetNode(list:any, classes:any, handleSettingsClick:Function, handleChartClick:Function, handleDeleteClick:Function, handleAddClick:Function, handleBatteryClick:Function, handleObjectChange:Function, handleObjectReorder:Function, state:IDashboardState, levels:number, dataDevices?:Array<IData>){
    if(!list.children)
        return;
    let level:number = levels + 1;

    let grouped:any = [];
    let single:any = [];
    
    let nene = list.children.map((item:any, index:number) => {

        if(item.objectType == 50){
            grouped.push(
                <div key={item.id} className={classes.root}>

                    <Accordion className="accordion">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            {state.editing?
                                <div style={{marginBottom:'auto', marginTop:'auto', zIndex:90}} >
                                    <IconButton size="small" onClick={()=>handleDeleteClick(item)} aria-label="view chart" >
                                        <RemoveCircleIcon />
                                    </IconButton>
                                </div>
                            :
                                null
                            }
                            {state.editing?
                                <input onChange={(evt:any)=>handleObjectChange(item, evt, 1)} onClick={function(event:any){event.stopPropagation()}} defaultValue={item.description} />
                            :
                                <Typography style={{fontWeight:'bold', marginBottom:'auto', marginTop:'auto'}}>{item.description}</Typography>
                            }
                            {/*<Typography style={{fontWeight:'bold', marginBottom:'auto', marginTop:'auto'}}>{item.description}</Typography>*/}
                        </AccordionSummary>
                        {state.editing?
                            <div>
                                <IconButton onClick={()=>handleAddClick(item.id)} style={{marginLeft:5}} size="small" aria-label="AddToGroup">
                                    <AddIcon />
                                </IconButton>
                            </div>
                        :
                            null
                        }
                        <AccordionDetails>
                        {GetNode(item, classes, handleSettingsClick, handleChartClick, handleDeleteClick, handleAddClick, handleBatteryClick, handleObjectChange, handleObjectReorder, state, level, dataDevices)}
                
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        } else {
            single.push(GetComponent(item,index, handleSettingsClick, handleChartClick, handleDeleteClick, handleBatteryClick, handleObjectReorder, state, dataDevices))

        }
        
    })
    //return (<div className="dynamic-grid">{nene}</div>);
    return(
        <div style={{width:'100%'}}>
            <div>{grouped}</div>
            <div className="dynamic-grid">{single}</div>
        </div>
    );
}
  
function BuildDashboard(items:any, classes:any, handleSettingsClick:Function, handleChartClick:Function, handleDeleteClick:Function, handleAddClick:Function, handleBatteryClick:Function, handleObjectChange:Function, handleObjectReorder:Function, state:IDashboardState, dataDevices?:Array<IData>){
    let grouped:any = [];
    let single:any = [];
    items.map((list:IObjects, index:number) => {
        if(list.objectType == 50){
            grouped.push(
                    <Accordion key={index} className="accordion">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {state.editing?
                                <div style={{marginBottom:'auto', marginTop:'auto', zIndex:90}} >
                                    <IconButton size="small" onClick={(e)=>handleDeleteClick(list, e)} aria-label="view chart" >
                                        <RemoveCircleIcon />
                                    </IconButton>
                                </div>
                            :
                                null
                            }
                            {state.editing?
                                <input onChange={(evt:any)=>handleObjectChange(list, evt, 1)} onClick={function(event:any){event.stopPropagation()}} defaultValue={list.description} />
                            :
                                <Typography style={{fontWeight:'bold', marginBottom:'auto', marginTop:'auto'}}>{list.description}</Typography>
                            }
                            {/*<Typography style={{fontWeight:'bold', marginBottom:'auto', marginTop:'auto'}}>{list.description}</Typography>*/}
                        </AccordionSummary>
                        {state.editing?
                            <div>
                                <IconButton onClick={()=>handleAddClick(list.id)} style={{marginLeft:5}} size="small" aria-label="Usuario">
                                    <AddIcon />
                                </IconButton>
                            </div>
                        :
                            null
                        }
                        <AccordionDetails>
                            {GetNode(list, classes, handleSettingsClick, handleChartClick, handleDeleteClick, handleAddClick, handleBatteryClick, handleObjectChange, handleObjectReorder, state, 0, dataDevices)}
                        </AccordionDetails>
                    </Accordion>
            );
        } else if(list.objectType == 60) {
            single.push(GetComponent(list,index, handleSettingsClick, handleChartClick, handleDeleteClick, handleBatteryClick, handleObjectReorder, state, dataDevices));
        }

    })
    return(
        <div className={classes.root}>
            <div>{grouped}</div>
            <div className="dynamic-grid">{single}</div>
        </div>
    );
}

interface IFormAddDeviceProps {
    //classes: any;
    handleCreateItem:Function;
    //handleCloseItemForm: Function;
    //loading:boolean;
    entityDataareaid: number;
    entityFarmId: number;
    dashboard: number;
}

interface IFormAddDeviceState {
    form:IIDevice;
    availableDevices?: Array<IDevice>;
}

class IFormAddDevice extends Component<IFormAddDeviceProps, IFormAddDeviceState> {
    constructor(props: IFormAddDeviceProps) {
        super(props);
        this.state = {
            form:{
                entityDataareaid: props.entityDataareaid,
                entityFarmId: props.entityFarmId, 
                dashboard: props.dashboard,
                description: "", 
                entityId: -1,
                objectType: -1,
                chartType: -1,
                dataType: -1,
                descriptionChart: undefined,
                highValue: undefined,
                lowValue: undefined,
                sendMessage: undefined,
                createAlarm: undefined
            },
        };
    }
    componentDidMount(){
        this.loadDevices();
    }

    loadDevices=async()=>{
        let devices:Array<IDevice> = await OrderService.loadAvailableDevices(this.props.entityDataareaid, this.props.entityFarmId)
        if(!devices){
            this.setState({availableDevices:[]});
        } else {
            devices = devices.map((device:IDevice, index:number)=>{
                return {
                    ...device,
                    name: `${device.tipo} - ${device.description} - ${device.referenceId?device.referenceId:device.dsCode}`,
                    id: device.id
                }
            })
            this.setState({availableDevices:devices});
        }
    }
    
    handleChangeUser=(id:any, event:any)=>{

        let newForm = Object.assign({}, this.state.form);
        newForm[id] = event.target.value;

        if(["objectType"].indexOf(id) > -1){
            switch(event.target.value){
                case 50:
                    newForm.createAlarm = undefined;
                    newForm.sendMessage = undefined;
                    newForm.dataType = undefined;
                    newForm.descriptionChart = undefined;
                    newForm.highValue = undefined;
                    newForm.lowValue = undefined;
                    newForm.chartType = undefined;
                    newForm.entityId = undefined;
                    break;
                case 60:
                    newForm.createAlarm = false;
                    newForm.sendMessage = false;
                    newForm.dataType = -1;
                    newForm.descriptionChart = `Tendencia últimas 24 h`;
                    newForm.highValue = 999999;
                    newForm.lowValue = -1000000;
                    newForm.chartType = -1;
                    newForm.entityId = -1;
                    break;
            }
        }
        this.setState({ form: newForm });
    }    

    verifyForm=(id?:string)=>{
        const item = this.state.form;

        if(id){
            if(typeof(item[id]) == "string" && item[id].length !== 0)
                return true;
            else if(typeof(item[id]) == "number" && item[id] !== -1)
                return true;
            else
                return false;
        } else {
            if(item.description.length == 0)
                return false;
            if([60].indexOf(item.objectType) > -1 && item.entityId == -1)
                return false;
            if([60].indexOf(item.objectType) > -1 && item.chartType == -1)
                return false;
            return true;
        }
    }

    handleAddDevice=()=>{
        const {form} = this.state;
        this.props.handleCreateItem(form);
    }

    render() {
        const {availableDevices, form} = this.state;
        if(availableDevices){
            return (
                <div style={{padding:10, border:'2px solid #ddd', borderRadius:'10px'}}>
                    <div>
                        <SelectorField
                            key={0}
                            name={`Tipo objeto`}
                            id={"objectType"}
                            fieldsData={[{id:50, name:'Agrupación'},{id:60, name:'Dispositivo'}]}
                            handleFieldChange={this.handleChangeUser}
                            required={true}
                            style={3}
                            deviceData={this.state.form}
                        />
                    </div>
                    {[60].indexOf(form.objectType) > -1?
                        <div style={{paddingTop:20, overflowX:'hidden'}}>
                            <SelectorField
                                key={0}
                                name={`Dispositivo`}
                                id={"entityId"}
                                fieldsData={availableDevices}
                                handleFieldChange={this.handleChangeUser}
                                required={true}
                                style={3}
                                deviceData={this.state.form}
                            />
                        </div>
                    :
                        null
                    }
                    <div>
                        <TextFieldInput
                            key={1}
                            name={`Nombre`}
                            id={`description`}
                            fieldsData={this.state.form}
                            handleFieldChange={this.handleChangeUser}
                            disabled={false}
                            size="medium"
                            shrink={true}
                            //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                            //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                            type={"small"}
                            required={true}
                            style={3}
                            helper={""}
                            maxText={50}
                            //noError={n.required?this.verifyForm(0,n.id):true}
                            noError={true}
                            multiline={false}
                        />
                    </div>
                    {[60].indexOf(form.objectType) > -1?
                        <div style={{marginTop: 15}}>
                            <SelectorField
                                key={2}
                                name={`Tipo dato`}
                                id={"dataType"}
                                fieldsData={[{id:1, name: `Tendencia`}, {id:2, name: `Acumulado`},{id:3, name: `Sumatorio tendencia`}]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={true}
                                style={3}
                                deviceData={this.state.form}
                            />
                        </div>
                    :
                        null
                    }
                    {[60].indexOf(form.objectType) > -1?
                        <div style={{marginTop: 15}}>
                            <SelectorField
                                key={2}
                                name={`Gráfico`}
                                id={"chartType"}
                                fieldsData={[{id:1, name: `Línea`}, {id:2, name: `Barra`}]}
                                handleFieldChange={this.handleChangeUser}
                                //disabled={n.id=="id"?!this.props.newProduct:this.state.editProduct}
                                required={true}
                                style={3}
                                deviceData={this.state.form}
                            />
                        </div>
                    :
                        null
                    }   
                    {this.verifyForm()?
                        <div>
                            <button className="button-save" onClick={()=>this.handleAddDevice()}>{`Añadir`}</button>
                        </div>
                    :
                        null
                    }

                </div>
            );
        } else {
            return(
                <div>
                    {`Cargando formulario...`}
                    <br/>
                    <Loader loading={true}/>
                </div>
            )
        }
    }
}
const FormAddDevice = withStyles(styles)(IFormAddDevice);

interface IIFormAddDeviceProps {
    handleCreateItem:Function;
    entityDataareaid: number;
    entityFarmId: number;
    dashboard: number;
}

interface IIFormAddDeviceState {
    form:IIIDevice;
}

class IIFormAddDevice extends Component<IIFormAddDeviceProps, IIFormAddDeviceState> {
    constructor(props: IIFormAddDeviceProps) {
        super(props);
        this.state = {
            form:{
                entityDataareaid: props.entityDataareaid,
                entityFarmId: props.entityFarmId, 
                dashboard: props.dashboard,
                temperatura: true,
                humedad: true,
                contador: true,
                silo: true
            },
        };
    }
    
    handleChangeDeviceType=(id:any, event:any)=>{
        let newForm = Object.assign({}, this.state.form);
        newForm[id] = event.target.checked;
        this.setState({ form: newForm });
    }    

    verifyForm=()=>{
        const item = this.state.form;

        if(item.temperatura)
            return true;
        else if(item.humedad)
            return true;
        else if(item.contador)
            return true;
        else if(item.silo)
            return true;
        return false;
    }

    handleAddDevice=()=>{
        const {form} = this.state;
        this.props.handleCreateItem(form);
    }

    render() {
        const {form} = this.state;
        return (
            <div style={{padding:10, border:'2px solid #ddd', borderRadius:'10px'}}>
                <div>
                    <CheckBoxField
                        key={0}
                        id={'temperatura'}
                        name={`Temperatura`}
                        fieldsData={form}
                        disabled={false}
                        handleFieldChange={this.handleChangeDeviceType}
                        shrink={false}
                        size={"small"}
                        type={"boolean"}
                    />
                </div>
                <div>
                    <CheckBoxField
                        key={0}
                        id={`humedad`}
                        name={`Humedad`}
                        fieldsData={form}
                        disabled={false}
                        handleFieldChange={this.handleChangeDeviceType}
                        shrink={false}
                        size={"small"}
                        type={"boolean"}
                    />
                </div>
                <div>
                    <CheckBoxField
                        key={0}
                        id={`contador`}
                        name={`Contadores`}
                        fieldsData={form}
                        disabled={false}
                        handleFieldChange={this.handleChangeDeviceType}
                        shrink={false}
                        size={"small"}
                        type={"boolean"}
                    />
                </div>
                <div>
                    <CheckBoxField
                        key={0}
                        id={`silo`}
                        name={`Silos`}
                        fieldsData={form}
                        disabled={false}
                        handleFieldChange={this.handleChangeDeviceType}
                        shrink={false}
                        size={"small"}
                        type={"boolean"}
                    />
                </div>  
                {this.verifyForm()?
                    <div>
                        <button className="button-save" onClick={()=>this.handleAddDevice()}>{`Añadir`}</button>
                    </div>
                :
                    null
                }
            </div>
        );
    }
}
const FormImportDevice = withStyles(styles)(IIFormAddDevice);

interface IDashboardProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    farmData?:IExploitation;
    loadExploitationData:Function;
    loading:boolean;
    badgeAlarms:number;
    dataDevices?:Array<IData>;
    updateDashboard:Function;
    loadedMarker?:IMarker;
}

interface IDashboardState {
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    searchValue:string;
    objectSelected?:IObjects;
    object2Delete?:IObjects;
    editing: boolean;
    loadingbackground:boolean;
    formimportdevices?:IIIDevice;
    objectReorder?:IObjects;
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
    _editedobjects:Array<IObjects> = [];

    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue:'',
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            editing: false,
            loadingbackground:false
        };
    }

    componentDidMount(){
        this.loadPage(false);
    }

    componentDidUpdate(prevprops:IDashboardProps){
        if(prevprops.farmData !== this.props.farmData){
            this.setState({loadingbackground:false});
        }
    }

    loadPage = async (refresh:boolean) => {
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);        
        const dashboard = this.props.match.params.dashboard;
        const marker = await OrderService.getMarker(entityFarmId,entityDataareaid);

        if(marker && dashboard){
            this.props.loadExploitationData(marker, refresh, 0);
        }
    }

    addDevice = async (item:IIDevice) => {
        const response = await OrderService.saveData(item, 4);
        this.timeoutManageLoadingBackground();
        this.loadPage(true);
    }

    deleteDevice = async (item:IObjects) => {
        const response = await OrderService.saveData(item, 5);
        this.loadPage(true);
    }

    calibrateDevice = async (item?:IObjects) => {
        if(!item)
            return;
        const response = await OrderService.saveData(item, 7);
    }

    importDevices = async (item:IIIDevice) => {
        const response = await OrderService.saveData(item, 11);
        //console.log(JSON.stringify(item))
        //const response = 1;
        let txt = `No hay dispositivos nuevos para añadir.`;
        if(response > 0)
            txt = `Se han añadido ${response} nuevo/s dispositivo/s.`;

        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:1,
            typeAction: -1,
            titleModal: `MENSAJE`,
            inputText: txt,
            fullScreenModal:false,
            loadingbackground: response > 0?true:false
        }, response > 0?()=> this.loadPage(true):undefined);
    }

    manageSearchObjects=(objects:Array<IObjects>, searchValue:string)=>{

        if(searchValue.length == 0){
            return objects;
            //this.setState({fmarkers: markers});
        } else {
            const searchedObjects = searchObject(objects, searchValue);
            return searchedObjects;
            //this.setState({fmarkers: searchedFarms});
        }
    }

    timeoutManageLoadingBackground = () => {
        setTimeout(() => {
            if(this.state.loadingbackground)
                this.setState({loadingbackground:false});
        }, 10000);
    }

    handleEditClick = () => {
        if(this.state.editing && this._editedobjects.length > 0){
            /*this._editedobjects.map((item:IObjects, i:number)=>{
                this.props.updateDashboard(item);
            })*/
            this.props.updateDashboard(this._editedobjects);
            this._editedobjects = [];
        }
            //console.log('guardo')
        this.setState((state:IDashboardState, props:IDashboardProps) => ({
            editing: !state.editing
        }))
    }
    handleSettingsClick = (item:IObjects) => {
        const {objectSelected} = this.state;
        this.setState({
            objectSelected:item,
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:3,
            typeAction: 1,
            titleModal: `Parámetros del dispositivo`,
            inputText: <FormSetting me={this.props.myInfo} handleCalibrationClick={this.handleCalibrationClick} item={objectSelected?objectSelected:item} onChangeItem={this.changeSettingItem}/>,
            fullScreenModal:false,
        })
        //this.setState({ [item.id]: !this.state[item.id] });
    };

    handleChartClick=(item:IObjects, data:any)=>{
        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:1,
            typeAction: 1,
            titleModal: item.descriptionChart,
            inputText: 
                <div style={{padding:10, border:'2px solid #ddd', borderRadius:'10px'}}>
                    {item.chartType == 1?
                        <Line data={data} height={300} />
                        :
                            item.chartType == 2?
                                <Bar data={data} height={300} />
                            :
                            <Bar data={data} height={300} />
                    }
                    
                </div>,
            fullScreenModal:true,
            maxwidthModal:'sm'
        })
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    changeSettingItem=(id:string, evt:any)=>{
        let newObject = cloneDeep(this.state.objectSelected);

        if(newObject){
            if(["sendMessage", "createAlarm"].includes(id)){ // Es un campo checkbox
                newObject[id] = evt.target.checked;
            } else if(["lowValue", "highValue", "calibrationValue"].includes(id)){ // Es un campo numérico decimal
                newObject[id] = String(evt.target.value).replace(/,/g, '.');
            } else {
                newObject[id] = evt.target.value;
            }
            this.setState({
                objectSelected: newObject,
                inputText: <FormSetting me={this.props.myInfo} handleCalibrationClick={this.handleCalibrationClick} item={newObject} onChangeItem={this.changeSettingItem}/>
            });
        }
    }

    handleCreateItem=(item:IIDevice)=>{
        this.setState({creationModalOpen:false, loadingbackground:true}, ()=>this.addDevice(item));
    }

    handleAddClick= (parentId:number)=>{
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);

        if(!entityFarmId || !entityDataareaid)
            return;
        
        this.setState({
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `Añadir objeto`,
            inputText: <FormAddDevice handleCreateItem={this.handleCreateItem} entityDataareaid={entityDataareaid} entityFarmId={entityFarmId} dashboard={parentId}/>,
            fullScreenModal:false,
        })
    }

    handleDeleteClick=(item:IObjects, evt?:any)=>{
        if(evt)
            evt.stopPropagation();

        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:2,
            typeAction: 2,
            titleModal: `MENSAJE`,
            inputText: 
                item.objectType == 50?
                    `¿Quiere eliminar la agrupación "${item.description}"?`
                        :
                            item.objectType == 60?
                                `¿Quiere eliminar el dispositivo "${item.description}" (${item.dsCode})?`
                                    : 
                                        `¿Quiere eliminar este objecto?`,
            object2Delete: item,
            fullScreenModal:false,
        })
    }

    handleBatteryClick=(item:IObjects, fData:any)=>{
        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:1,
            typeAction: -1,
            titleModal: `Detalle batería`,
            inputText: <FormBattery item={item} fData={fData}/>,
            fullScreenModal:false,
        })
    }

    handleObjectChange=(item:IObjects, evt:any, action:number)=>{
        switch(action){
            case 1: // Cambiar de nombre una agrupación
                let nobject = cloneDeep(item);
                nobject.description = evt.target.value;
                const index = findIndex(this._editedobjects, function(o){return o.id === nobject.id});
                if(index > -1)
                    this._editedobjects.splice(index, 1);
                this._editedobjects.push(nobject);
                break;
        }

    }

    handleImportItem=(item:IIIDevice)=>{
        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:2,
            typeAction: 4,
            titleModal: `Importación dispositivos`,
            inputText: `¿Quiere añadir todos los dispositivos no configurados en su panel del tipo seleccionado?`,
            fullScreenModal:false,
            formimportdevices: item
        });
    }

    handleImportDeviceClick= (parentId:number)=>{
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);

        if(!entityFarmId || !entityDataareaid)
            return;
        
        this.setState({
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `Importación dispositivos`,
            inputText: <FormImportDevice handleCreateItem={this.handleImportItem} entityDataareaid={entityDataareaid} entityFarmId={entityFarmId} dashboard={parentId}/>,
            fullScreenModal:false,
        });
    }

    handleObjectReorder = (item:IObjects) => {
        //console.log('reorder', item);
        //console.log(this.props.farmData?.data.dashboards)
        const {farmData} = this.props;
        if(!farmData || !farmData.data.dashboards)
            return;

        this.setState({
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `Edición posición`,
            inputText: <FormReorder items={farmData.data.dashboards} onPinItem={this.handleObjectReorderClick}/>,
            fullScreenModal:false,
            objectReorder: item
        });
    }

    handleObjectReorderClick = (e:any, item:IObjects) => {
        const {objectReorder} = this.state;
        if(!objectReorder)
            return;
        
        //console.log('2reorder', objectReorder);
        //console.log('clicked', item);
    }

    renderDashboard=(objects:Array<IObjects>,classes:any)=>{
        const {editing} = this.state;
        const {myInfo} = this.props;
        
        const dashboard = this.props.match.params.dashboard;
        let originalDate = new Date().getTime();
        let items:any = BuildDashboard(objects, classes, this.handleSettingsClick, this.handleChartClick, this.handleDeleteClick, this.handleAddClick, this.handleBatteryClick, this.handleObjectChange, this.handleObjectReorder, this.state, this.props.dataDevices)
        let finalDate = new Date().getTime();
        let diff = finalDate - originalDate;
        //console.log('renderDashboard', objects);
        /*let items = gDashboard(objects, dashboard).map((object:IObjects, index:number)=>{
            return(
                <Widget04 key={index} pObject={object}/>
            );
        })*/
        return(
            <div>
                {myInfo && myInfo.editMode?
                    <div style={{display:'inline-flex'}}>
                        <IconButton onClick={()=>this.handleEditClick()} style={{marginLeft:5}} size="small" aria-label="Usuario">
                            {editing?
                                <DoneIcon />
                            :
                                <EditIcon />
                            }
                        </IconButton>
                        {editing?
                            <div>

                            <IconButton  onClick={()=>this.handleAddClick(dashboard)} style={{marginLeft:5}} size="small" aria-label="Usuario">
                                <AddIcon />
                            </IconButton>

                            <IconButton  onClick={()=>this.handleImportDeviceClick(dashboard)} style={{marginLeft:5}} size="small" aria-label="Usuario">
                                <ImportDeviceIcon />
                            </IconButton>
                            
                            </div>
                        :
                            null
                        }
                    </div>
                :
                    null
                }
                <div>
                    {items}
                </div>
            </div>
        );
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, objectSelected: undefined });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica guardar el setting de un dispositivo
                this.setState({creationModalOpen:false, objectSelected: undefined});
                this.props.updateDashboard([this.state.objectSelected])
                break;
            case 2: // Cuando el modal implica eliminar un objecto del dashboard
                const {object2Delete} = this.state;
                this.setState({creationModalOpen:false, loadingbackground:true});
                this.timeoutManageLoadingBackground();
                if(object2Delete)
                    this.deleteDevice(object2Delete)
                break;
            case 3: // Cuando el modal implica calibrar un objeto
                this.calibrateDevice(this.state.objectSelected);
                this.setState({creationModalOpen:true, modalType:1, inputText:`El valor del dispositivo se ajustará en un máximo de 24 horas.`, objectSelected: undefined});
                break;
            case 4: // Cuando el modal implica importar dispositivos
                const {formimportdevices} = this.state;
                if(!formimportdevices)
                    return;
                this.setState({creationModalOpen:false, loadingbackground: true}, ()=>this.importDevices(formimportdevices));
            default:
                break;
        }
    }

    handleSearchValueChange=(e:any)=>{
        this.setState({searchValue: e.target.value});
    }

    handleFilterClick=()=>{

    }

    handleCalibrationClick=(item:IObjects)=>{
        //console.log(item);
        this.setState({
            creationModalOpen:true,
            closeButtonModal:false,
            modalType:2,
            typeAction: 3,
            titleModal: `CALIBRACIÓN`,
            inputText: `¿Quiere ajustar el dispositivo (${item.description}) a ${item.calibrationValue} ${item.ud}?`,
            fullScreenModal:false,
            objectSelected: item
        })
    }

    render() {
        const {farmData, badgeAlarms, classes, loadedMarker, myInfo} = this.props;
        const {searchValue, loadingbackground} = this.state;
        //console.log(farmData)
        return (
            <div>
            <Layout me={myInfo} marker={loadedMarker} history={this.props.history} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} onSearchChange={(evt:any)=>this.handleSearchValueChange(evt)} searchValue={this.state.searchValue} placeholderSearch={"Buscar dispositivo..."} badgeAlarms={badgeAlarms} navBar={farmData?.data.navbar}/>

                    <div className='dashboard-content'>

                        {farmData && farmData.data.dashboards?
                            this.renderDashboard(this.manageSearchObjects(farmData.data.dashboards, searchValue), classes)
                        :
                            'Cargando...'
                        }
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.inputText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.typeAction}
                                loadingModal={false}
                                titleModal={this.state.titleModal}
                                closeButton={this.state.closeButtonModal}
                                fullscreen={this.state.fullScreenModal}
                                maxwidth={this.state.maxwidthModal}
                            />
                        :
                            null
                        }
                        <LoaderBeauty visible={loadingbackground}/>
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);