import React from 'react';
import ReactDOM from 'react-dom';
import './indexApp.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, HashRouter, Route, Switch } from 'react-router-dom';

const Container = () => {
    return (
        <HashRouter>
        <Switch>
          <Route path="/" name="Home" component={App} />
        </Switch>
      </HashRouter>
    );
}

ReactDOM.render(<Container />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
