import React from 'react';
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import PinIcon from '@material-ui/icons/PinDropOutlined';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function GetNode(list:any, classes:any, handleClick:Function, state:any, levels:number, handleItemClick:Function){

  let level:number = levels + 1;
  if(!list.children)
    return;
  let nene = list.children.map((item:any, index:number) => {
    return (
      <div key={item.id}>
        {item.children != null ? (
          <div key={item.id}>
              <ListItem>
                <IconButton size="small" aria-label="pin" onClick={(e:any)=>handleItemClick(e, item)} key={item.id} >
                  <PinIcon/>
                </IconButton>
                <ListItemText
                    primary={item.description}
                    style={{color:'#000'}}
                />
                  {state[item.Iidd] ? (
                    <IconButton size="small" aria-label="pin" onClick={(e:any)=>handleClick(e, item)} key={index} >
                      <ExpandLess style={{color:'#000'}} />
                    </IconButton>
                  ) : (
                    <IconButton size="small" aria-label="pin" onClick={(e:any)=>handleClick(e, item)} key={index} >
                      <ExpandMore style={{color:'#000'}} />
                    </IconButton>
                  )}
              </ListItem>
              <Collapse
                  key={list.children.id}
                  component="li"
                  in={state[item.id]}                        
                  timeout="auto"
                  unmountOnExit
              >
                <List disablePadding style={{paddingLeft: `${20*level}px`}}>
                    {GetNode(item, classes, handleClick, state, level, handleItemClick)}
                </List>
              </Collapse>{" "}
          </div>
        ) : (
            <ListItem>
              <IconButton size="small" aria-label="pin" onClick={(e:any)=>handleItemClick(e, item)} key={item.id} >
                <PinIcon/>
              </IconButton>
              <ListItemText
                  style={{color:'#000'}}
                  primary={item.description}
              />
            </ListItem>
        )}
      </div>
    );
})
return nene;
}

function BuildNavBar(items:any, classes:any, handleClick:Function, state:any, handleItemClick:Function){
  //console.log(items)
  return(
    <div>
      {items.map((list:any, i:number) => {
          return (
              <List
                  key={list.id}
                  subheader={
                      <div style={{display:'inline-flex'}}>
                        <IconButton aria-label="pin" onClick={(e:any)=>handleItemClick(e, list)} key={i} >
                          <PinIcon/>
                        </IconButton>
                        <ListSubheader style={{color:'#000', fontWeight:'bold'}} >{list.description}</ListSubheader>
                      </div>
                  }
              >
                  {GetNode(list, classes, handleClick, state, 0, handleItemClick)}
                  <Divider key={list.id} absolute style={{backgroundColor:'#ddd'}}/>
              </List>
          );
      })}
  </div>
  );
}

interface INestedListProps {
  classes: any;
  items:any;
  onClick: Function;
}

class NestedList extends React.Component<INestedListProps, any> {
  state:any = {};
  handleClick = (e:any, item:any) => {
      this.setState({ [item.id]: !this.state[item.id] });
  };

  render() {
    const { classes, items, onClick } = this.props;
    return (
      <div>
        {BuildNavBar(items, classes, this.handleClick, this.state, onClick)}

      </div>
    );
  }
}
export default withStyles(styles)(NestedList);