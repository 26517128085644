import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
} from "react-google-maps";
import {MAP} from 'react-google-maps/lib/constants'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import ClusterRed from '../Assets/googleMarkers/ClusterRed.png';
import ClusterGreen from '../Assets/googleMarkers/ClusterGreen.png';
import ClusterOrange from '../Assets/googleMarkers/ClusterOrange.png';
import { compose, withHandlers } from 'recompose';  
import {IMarker} from '../types';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 20px)',
            margin: 'auto',
        },
        input: {
            marginLeft: '10px',
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
    }),
);
  
const clusterStyle = [		
  {		
    url: ClusterRed,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 20],
    textSize:15
  },	
  {		
    url: ClusterRed,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 18.5],
    textSize:15
  },	
  {		
    url: ClusterRed,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 15],
    textSize:15
  },	 	
  {		
    url: ClusterGreen,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 20],
    textSize:15
  },	
  {		
    url: ClusterGreen,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 18.5],
    textSize:15
  },		 	
  {		
    url: ClusterGreen,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 15],
    textSize:15
  },	 
  {		
    url: ClusterOrange,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 20],
    textSize:15
  },	
  {		
    url: ClusterOrange,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 18.5],
    textSize:15
  },		 	
  {		
    url: ClusterOrange,
    height: '15',
    width: '15',
    textColor: '#333',
    anchorText: [20, 15],
    textSize:15
  },		
]
  
function calculateZIndexCluster(markers: any){
  let maxIndex = Math.max.apply(Math, markers.map(function(o:any) { return o.zIndex; }))
  let idx = 1;
  switch(maxIndex){
    case 1:
    case 2:
      if(markers.length < 10)
        idx = 1;
      else if(markers.length < 100)
        idx = 2;
      else
        idx = 3;
      break;
    case 99:
      if(markers.length < 10)
        idx = 7;
      else if(markers.length < 100)
        idx = 8;
      else
        idx = 9;
      break;
    default:
      if(markers.length < 10)
        idx = 4;
      else if(markers.length < 100)
        idx = 5;
      else
        idx = 6;
      break;
  }

  let result = {
    text: markers.length,
    index: idx
  };
  return result;
}

declare const google: any;

const MapWithAMarker = compose(withScriptjs, withGoogleMap,  withHandlers(():any => {
    
  const refs:any = {
    map: undefined,
    mapObject: undefined
  }

  return {
    severalMethods: (props:any)=> () => {
      //https://developers.google.com/maps/documentation/javascript/reference/map#Map.getBounds
      // Fit map
        /*if(refs.map){
        const mapBounds = refs.map.getBounds();
        //just add markers position into mapBounds
          props.markers.forEach((marker:IMarker)=>{
          mapBounds.extend({lat:marker.latitude,lng:marker.longitude})
        });
  
          refs.map.fitBounds(mapBounds);
          }*/
      // Modificar el zoom
          //refs.map.setZoom()
      // Modificar el centro
          //refs.map.panTo({lat:x,lng:y})
    },
    goToSearch: (props:any) => () => {
      if(!refs.map || !refs.mapObject)
        return;

      let mapBounds = new google.maps.LatLngBounds();

      if(props.markers.length > 0) {

        props.markers.map((item:IMarker) => {
          mapBounds.extend({lat:item.latitude,lng:item.longitude});
          return item.id
        });

        refs.map.fitBounds(mapBounds);

      } else {

        refs.map.panTo(props.defaultLocation.coords);
        refs.mapObject.setZoom(props.defaultLocation.zoom);

      }

    },
    onMapMounted: () => (ref:any) => {
      if(!ref)
        return;
      refs.map = ref;
      refs.mapObject = ref.context[MAP];
    },
    onZoomChanged: (props:any) => () => {
      if(!refs.map)
        return;
      let zoom = refs.map.getZoom();
      props.onChangeZoom(zoom)
    },
    onCenterChanged: (props:any) => () => {
      if(!refs.map)
        return;
      let coord = refs.map.getCenter();
      props.onChangeCoords(coord)
    },
    onMarkerClustererClick: () => (markerClusterer:any) => {
      //const clickedMarkers = markerClusterer.getMarkers()
      //console.log(`Current clicked markers length: ${clickedMarkers.length}`)
    },
  }
  }))((props:IMapsProps|any) => {

    // Añade el listener de teclado y lo configura para eliminar cuando se quite el elemento
    /*const onKeyDown = (event:any) => { 
      if(event.key == "Enter") {
        props.goToSearch();
      }
    }

    useEffect(() => {
      window.addEventListener('keydown', onKeyDown)
  
      return () => { window.removeEventListener('keydown', onKeyDown) }
    }, []);*/

    useEffect(() => {
      if(props.nsearch > 0)
        props.goToSearch();
    }, [props.nsearch]);

    return (
      <GoogleMap
        onDblClick={props.resetCoordZoom}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        onCenterChanged={props.onCenterChanged}
        options= 
          {{fullscreenControl:false,
          streetViewControl:false}}
          //defaultZoom={calculateZoom(lastZoom)}
          defaultZoom={props.location.zoom}
          //defaultCenter={lastCoord==undefined?{ lat: props.currentLocation.latitude, lng: props.currentLocation.longitude }:{ lat: JSON.parse(lastCoord).lat, lng: JSON.parse(lastCoord).lng }}
          defaultCenter={props.location.coords}
        >    
        {!props.issearching?
          <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={props.clusterSize}
            calculator={(markers:any)=>calculateZIndexCluster(markers)}
            styles={clusterStyle}
          >
            
            {props.markers.map((marker:IMarker) => {
              let keyId:string = ``;
              props.id.map((item:string)=>{
                keyId = keyId + marker[item]
              });
              
              let selectedKeyId:string = ``;
              props.id.map((item:string)=>{
                if(props.markerSelected == undefined)
                  return;
                  selectedKeyId = selectedKeyId + props.markerSelected[item]
              });

              return (
                <Marker
                  key={keyId}
                  onClick={(e:any)=>marker.handleClick(marker, e)}
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  icon={marker.icon}
                  zIndex={marker.priority}       

                >
                  {selectedKeyId === keyId &&
                    <InfoWindow onCloseClick={marker.handleCloseClick}>
                      {marker.dialogBox}
                    </InfoWindow>}
                </Marker>
              )
            })}
          </MarkerClusterer>
        :
          props.markers.map((marker:IMarker) => {
            let keyId:string = ``;
            props.id.map((item:string)=>{
              keyId = keyId + marker[item]
            });
            
            let selectedKeyId:string = ``;
            props.id.map((item:string)=>{
              if(props.markerSelected == undefined)
                return;
                selectedKeyId = selectedKeyId + props.markerSelected[item]
            });

            return (
              <Marker
                key={keyId}
                onClick={(e:any)=>marker.handleClick(marker, e)}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                icon={marker.icon}
                zIndex={marker.priority}
                
              >
                {selectedKeyId === keyId &&
                  <InfoWindow onCloseClick={marker.handleCloseClick}>
                    {marker.dialogBox}
                  </InfoWindow>}
              </Marker>
            )
          })
        }
    </GoogleMap>
  )
})

interface IMapsProps {
    id:Array<string>;
    infoWindow:any;
    location:ILocation;
    defaultLocation:ILocation;
    markerSelected?:IMarker;
    markers:Array<IMarker>;
    mapsUrl: string;
    clusterSize:number;
    onChangeZoom:Function;
    onChangeCoords:Function;
    nsearch: number;
    issearching: boolean;
}

interface ILocation {
  coords:IILocation;
  zoom:number;
}

interface IILocation {
  lat:number;
  lng:number;
}

export default function GoogleMaps(props: IMapsProps) {
    const classes = useStyles();
    //console.log(props.markers)

    const mapsProps = { // make sure all required component's inputs/Props keys&types match
      id: props.id,
      infoWindow: props.infoWindow,
      location: props.location,
      defaultLocation: props.defaultLocation,
      markerSelected: props.markerSelected,
      markers: props.markers,
      googleMapURL: props.mapsUrl,
      loadingElement: <div style={{ height: `100%`, width:'100%' }} />,
      containerElement: <div style={{ height: '100%', width:'100%', marginLeft:0 }} />,
      mapElement: <div style={{ height: `100%`, width:'100%' }} />,
      onChangeZoom:props.onChangeZoom,
      onChangeCoords:props.onChangeCoords,
      nsearch: props.nsearch,
      issearching: props.issearching
    }

    return (
        <MapWithAMarker
          {...mapsProps}
        />
    );
}