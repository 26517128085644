import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IMarker, IMe, IObjects } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep, filter} from 'lodash';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    new:{
        marginBottom:20,
        borderRadius:'10px',
        padding:10,
    },
});

interface INotificacionesProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface INotificacionesState {
    me?:IMe;
}

class Notificaciones extends Component<INotificacionesProps, INotificacionesState> {
    constructor(props: INotificacionesProps) {
        super(props);
        this.state = {
            me: props.myInfo
        };
    }

    componentDidUpdate(prevprops:INotificacionesProps){
        if(prevprops.myInfo !== this.props.myInfo){
            this.setState({me:this.props.myInfo})
        }
    }

    componentWillUnmount(){
        const {me} = this.state;
        if(!me)
            return;
        let nNews = filter(me.news, function(o){return !o.viewed});
        if(nNews.length > 0){
            let uNews = me.news.map((item)=>{
                return {
                    ...item,
                    viewed: true
                }
            })
            me.news = uNews;
            this.props.updateMe(me, 2);
        }
    }

    handleFieldChange=(id:string, evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[id] = evt.target.checked;
            this.setState({me:newMe});
        }
    }

    handleFieldTextChange=(evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[evt.target.id] = evt.target.value;
            this.setState({me:newMe});
        }
    }

    renderNews=(me:IMe, classes:any)=>{
        let items = me.news.map((item, index)=>{
            return(
                <div className={classes.new} style={{backgroundColor:!item.viewed?'rgb(0, 59, 113, 0.20)':'rgb(187, 187, 187, 0.20)'}} key={index} dangerouslySetInnerHTML={{ __html: item.new }} />
            );
        });
        return items;
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {me} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {me?
                            <div style={{marginTop:10, marginLeft:10, marginRight:10}}>
                                <div style={{marginBottom:30}}>
                                    <strong style={{fontSize:'18px'}}>{`Noticias`}</strong>
                                    <p>
                                        {`Sección donde podrás encontrar las últimas noticias referente a las prestaciones de Nealia Metrics.`}
                                    </p>
                                </div>
                                <div>
                                    {me.news.length == 0?
                                        <strong>{`No hay noticias que mostrar.`}</strong>
                                    :
                                        this.renderNews(me, classes)
                                    }
                                </div>
                            </div>
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);