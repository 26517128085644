import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    clientId: '64482eed-655b-47fa-a729-0d0bba59dfe5',
    tenant: '779fefa3-287c-452b-9106-b9cbb436236e',
    endpoints: {
        api: '64482eed-655b-47fa-a729-0d0bba59dfe5',
    },
    cacheLocation: 'localStorage',
};
export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);