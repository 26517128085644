import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconBuild from '@material-ui/icons/BuildOutlined';
import { IBattery, IObjects } from '../types';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import {GetFormattedDate} from '../vc_general';
import {addThousandsAndReplaceDecimal, addZeroes} from '../helpers';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            //display: 'flex',
            //alignItems: 'center',
            //width: 'calc(100% - 20px)',
            //margin: 'auto',
            backgroundColor:'transparent',
            borderWidth: 0, 
            borderColor: '#ccc',
            borderStyle: 'solid',
            borderRadius:'10px'
        },
        input01: {
            marginTop:5,
            marginBottom:5,
            marginLeft:5,
            marginRight:5,
            padding: 8,
            width:'80%'
        },
        input02: {
            marginTop:10,
            marginBottom:10,
            padding: 8,
            width:'90%'
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            borderBottomColor:'#BBBBBB',
        },
        dots:{
            display:'inline-block',
            marginLeft:10,
            width:'15px',
            height:'15px',
            borderRadius:'50%'
        }
    }),
);

function formatterField(data:number){
    if(typeof data === 'number')
        return addThousandsAndReplaceDecimal(addZeroes(Number(data.toFixed(2))),',');
    
}

interface IFormBatteryProps {
    item: IObjects;
    fData: any;
}

export default function FormBattery(props: IFormBatteryProps) {
    const classes = useStyles();
    const {item, fData} = props;
    const battery = item.battery;
    return (
        <div style={{height:'calc(50vh - 20px)', width:'100%'}}>
            <div style={{width:'100%', overflowX:'auto'}} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{backgroundColor:'rgba(187, 187, 187, 0.3)'}} key={0}>
                            <TableCell size="small" align="left"><strong>{`Información`}</strong></TableCell>
                            <TableCell colSpan={2} size="small" align="center"/>
                        </TableRow>
                    </TableHead>
                        {battery && battery.status !== null?
                    <TableBody>
                        <TableRow key={0}>
                            <TableCell className={classes.divider} align="left">{`Estado`}</TableCell>
                            <TableCell className={classes.divider} align="center"><div style={{display:'flex'}}>{fData[2]}<div className={classes.dots} style={{backgroundColor:fData[1]}}/></div></TableCell>
                        </TableRow>
                        <TableRow key={1}>
                            <TableCell className={classes.divider} align="left">{`Batería`}</TableCell>
                            <TableCell className={classes.divider} align="center">
                                {`${(battery.status * 100).toFixed(0)} % `}
                                {/*<br/>
                                {`(${formatterField(battery.value)} V)`}*/}
                            </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                            <TableCell className={classes.divider} align="left">{`Batería hasta`}</TableCell>
                            <TableCell className={classes.divider} align="center">{`${GetFormattedDate(battery.estimatedDate)}`}</TableCell>
                        </TableRow>
                        <TableRow key={3}>
                            <TableCell className={classes.divider} align="left">{`Máximo`}</TableCell>
                            <TableCell className={classes.divider} align="center">
                                {`${formatterField(battery.max)} V`}
                            </TableCell>
                        </TableRow>
                        <TableRow key={4}>
                            <TableCell className={classes.divider} align="left">{`Valor`}</TableCell>
                            <TableCell className={classes.divider} align="center">
                                {`${formatterField(battery.value)} V`}
                            </TableCell>
                        </TableRow>
                        <TableRow key={5}>
                            <TableCell className={classes.divider} align="left">{`Mínimo`}</TableCell>
                            <TableCell className={classes.divider} align="center">
                                {`${formatterField(battery.min)} V`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                        :   
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <strong>{`La estimación de batería se está calculando, en unas horas dispondrás de esa información.`}</strong>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        }
                </Table>
            </div>
        </div>
    );
}