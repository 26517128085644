import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IGeneralSetting, IMarker, IMe, IObjects } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import {replaceComma} from '../vc_general';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SelectorField, CheckBoxField, TextFieldInput, SwitchField } from './FormsWidgets';
import CreateModal from './CreateDialog'
import OrderService from '../ApiClients/OrderService';

const formSetting00 = {deviceTypeId: -1};

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

function verifyLimit(item:IGeneralSetting, limit:number){

    if(limit == 1) { // Límite superior
        if(!item.highValue) // Debe tener un valor
            return false;
        if(!item.lowValue) // Correcto mientras no tiene valor
            return true;
        if(Number(item.highValue) > Number(item.lowValue)) // Si es más grande perfecto
            return true;
        return false;
    } else { // Límite inferior
        if(!item.lowValue) // Debe tener un valor
            return false;
        if(!item.highValue) // Correcto mientras no tiene valor
            return true;
        if(Number(item.lowValue) < Number(item.highValue)) // Si es más pequeño perfecto
            return true;
        return false;
    }
}

interface IGeneralSettingsProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface IGeneralSettingsState {
    register: IGeneralSetting;
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    saving: boolean;
}

class Notificaciones extends Component<IGeneralSettingsProps, IGeneralSettingsState> {
    constructor(props: IGeneralSettingsProps) {
        super(props);
        this.state = {
            register: formSetting00,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            saving: false
        };
    }

    componentDidUpdate(prevprops:IGeneralSettingsProps){

    }

    saveSettings=async(register:any)=> {
        const response = await OrderService.saveData(register, 10);
        let text = `¡ATENCIÓN! No se ha podido realizar dicha acción.`;
        let resetform = false;
        if(response == 1) {
            text = `Cambios aplicados correctamente.`;
            resetform = true;
        }

        this.setState({creationModalOpen:true, modalType: 1, inputText: text, saving: false, register: (resetform?formSetting00:register)});
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica guardar el setting 00
                this.setState({saving: true}, ()=>this.saveSettings(this.state.register));
                break;
            default:
                break;
        }
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);

        if(["highValue","lowValue"].indexOf(id) > -1)
            newRegister[id] = replaceComma(evt.target.value);
        else
            newRegister[id] = evt.target.value;

        this.setState({register:newRegister});
    }

    handleSave00=()=>{
        this.setState({
            creationModalOpen: true,
            inputText: `¿Quiere guardar la configuración de alarma? Se actualizarán los rangos de todos los dispositivos según el tipo seleccionado, se desactivarán las alarmas generadas sin recibir ninguna notificación y no se crearán nuevas alarmas hasta recibir un nuevo dato.`,
            modalType: 2,
            typeAction: 1
        });
    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {register} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {myInfo?
                            <div style={{marginTop:10, marginLeft:10}}>
                                <div style={{marginBottom:30}}>
                                    <strong style={{fontSize:'18px'}}>{`Ajustes generales`}</strong>
                                    <p>
                                        {`Aquí encontrarás y podrás cambiar los ajustes generales de Nealia Metrics.`}
                                    </p>
                                </div>
                                <div>
                                    <Accordion className="accordion">
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                            <Typography style={{fontWeight:'bold', marginBottom:'auto', marginTop:'auto'}}>{`Configuración alarmas`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <table style={{width:'100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <SelectorField
                                                                key={0}
                                                                name={`Tipo dispositivo`}
                                                                id={`deviceTypeId`}
                                                                fieldsData={[{id:1,name: `Temperatura`},{id:2,name: `Humedad`}]}
                                                                handleFieldChange={this.handleChangeUser}
                                                                required={true}
                                                                style={1}
                                                                deviceData={register}
                                                            />      
                                                            <TextFieldInput
                                                                key={1}
                                                                name={`Valor alto`}
                                                                id={`highValue`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="small"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={1}
                                                                helper={``}
                                                                maxText={8}
                                                                noError={verifyLimit(register, 1)}
                                                                disabled={false}
                                                            />   
                                                            <TextFieldInput
                                                                key={2}
                                                                name={`Valor bajo`}
                                                                id={`lowValue`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="small"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={1}
                                                                helper={``}
                                                                maxText={8}
                                                                noError={verifyLimit(register, 2)}
                                                                disabled={false}
                                                            />                                                
                                                        </td>
                                                    </tr>
                                                    
                                                    {(register.deviceTypeId > 0 && verifyLimit(register, 1) && verifyLimit(register, 2))?
                                                        <tr>
                                                            <td colSpan={2} style={{fontSize:'11px'}}>
                                                                <button className={classes.buttonSave} onClick={()=>this.handleSave00()}>{`Guardar`}</button>
                                                            </td>
                                                        </tr>
                                                    :
                                                        null
                                                    }


                                                </tbody>
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                {this.state.creationModalOpen?
                                    <CreateModal
                                        onCloseCreationModal={this.onCloseCreationModal}
                                        isOpen={this.state.creationModalOpen}
                                        inputText={this.state.inputText}
                                        typeModal={this.state.modalType}
                                        action={this.handleCreateModal}
                                        typeAction={this.state.typeAction}
                                        loadingModal={this.state.saving}
                                        titleModal={this.state.titleModal}
                                        closeButton={this.state.closeButtonModal}
                                        fullscreen={this.state.fullScreenModal}
                                        maxwidth={this.state.maxwidthModal}
                                    />
                                :
                                    null
                                }
                            </div>
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);