import React from 'react';
import { IParte } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import TruckIcon from '@material-ui/icons/LocalShippingOutlined';
//import viajeIcon from '../Assets/Viaje.jpg';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
//import {getStatusLinea, GetFormattedDate} from '../helpers';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        cursor: 'pointer',
        paddingBottom: '10px',
        position: 'relative',
        border:'2px solid #003B71',
    },
    bottomActionsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 15,
        right: 0,
        height: '30px',
        borderTop: '1px solid rgba(0,0,0,0.31)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        padding: '5px 0px',
        color: '#284770',
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    bottomRightActionsContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: 1,
        fontSize:'12px',
    },
    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    },
    status: {
        borderRadius: '10px',
        padding: '5px 10px',
        fontSize: '11px',
    }
}));

const ParteListItem = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IParte = data[index];
    //let statusProperties : Array<string> = getStatusDescriptionColor(item.estado)

    return (
        <div style={style} key={index}>
            <ListItem  onClick={item.onItemClick} classes={{ root: classes.item }}>
                <ListItemIcon>
                    <Avatar alt="Linea Icon" classes={{
                        root: classes.root,
                        img: classes.img
                    }} />
                </ListItemIcon>
                <ListItemText primary={`${item.name}`} className={classes.mainHeader} /*secondary={`Precio: ${item.price} €/${item.saleUnit}`}*/ />
                <ListItemSecondaryAction style={{ paddingBottom: '0px' }}>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <ArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

ParteListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default ParteListItem;