import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import './Alarms.css';
import { IMe, IObjects, IExploitation, IAlarm, IMarker, IIAlarm } from '../../types';
import CreateModal from '../CreateDialog'
import {searchObject} from '../../helpers';
import OrderService from '../../ApiClients/OrderService';
import Layout from '../Layout';
import EnhancedTable from './Table';
import {filter, includes} from 'lodash';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
        nested: {
        paddingLeft: theme.spacing(4),
    },
});

interface IAlarmsProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loadExploitationData:Function;
    farmData?:IExploitation;
    badgeAlarms: number;
    loadedMarker?:IMarker;
    tipo:number;
}

interface IAlarmsState {
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    searchValue:string;
    alarms?: Array<IAlarm>;
}

class Dashboard extends Component<IAlarmsProps, IAlarmsState> {
    constructor(props: IAlarmsProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue:'',
            titleModal: 'MENSAJE',
            closeButtonModal: false,
        };
    }

    componentDidMount(){
        this.LoadAlarms(false);
    }

    componentDidUpdate(prevprops:IAlarmsProps){
        if(prevprops.tipo !== this.props.tipo){
            this.LoadAlarms(false);
        }
    }

    LoadAlarms = async (refresh:boolean) => {
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);  
        const marker = await OrderService.getMarker(entityFarmId,entityDataareaid);

        if(marker){
            this.props.loadExploitationData(marker, refresh, this.props.tipo == 0?1:2);
        }
    }

    manageSearchObjects=(objects:Array<IObjects>, searchValue:string)=>{

        if(searchValue.length == 0){
            return objects;
            //this.setState({fmarkers: markers});
        } else {
            const searchedObjects = searchObject(objects, searchValue);
            return searchedObjects;
            //this.setState({fmarkers: searchedFarms});
        }
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleFilterAlarms=(alarms?:Array<IAlarm>)=>{
        const searchValue = this.state.searchValue.toLowerCase();
        let nalarms = alarms;

        if(nalarms && nalarms.length > 0 && searchValue && searchValue.length > 0){
            nalarms = filter(nalarms, function(o){return includes(o.alarmTypeDsc.toLowerCase(), searchValue) || includes(JSON.parse(o.jsonAct).devName.toLowerCase(), searchValue) });
        }

        return nalarms;
    }

    RenderAlarmTable=(farmData:IExploitation, tipo: number)=>{
        //console.log('estas si',farmData.data.alarmsA)
        const columns = [
            //{ name: 'Id', id: 'Id', size: '25', isSortable: true, numeric: false, disablePadding: true, isDate: false },
            { name: 'Nombre', id: 'NAME', size: '21', isSortable: true, numeric: false, disablePadding: true, isDate: false },
            { name: 'Tipo Alarma', id: 'AlarmTypeDescription', size: '21', isSortable: true, numeric: false, disablePadding: true, isDate: false },
            { name: 'Descripción', id: 'AlarmDescription', size: '21', isSortable: true, numeric: false, disablePadding: true, isDate: false },
            { name: 'TS_ACT', id: 'TS_ACT', size: '21', isSortable: true, numeric: false, disablePadding: true, isDate: true },
            { name: 'TS_DES', id: 'TS_DES', size: '21', isSortable: true, numeric: false, disablePadding: true, isDate: true },
        ];

        return(
            <div>
                <div>
                    {(tipo==0 && farmData.data.alarmsA && farmData.data.alarmsA.length > 0) || (tipo==1 && farmData.data.alarmsH && farmData.data.alarmsH.length > 0)?
                        <EnhancedTable
                            columns={columns}
                            dataset={tipo==0?
                                this.handleFilterAlarms(farmData.data.alarmsA)
                            :
                                this.handleFilterAlarms(farmData.data.alarmsH)
                            }
                        />
                    :
                            `Actualmente no se ha producido ninguna alarma.`
                    }
                </div>
            </div>
        );
    }

    handleSearchValueChange=(e:any)=>{
        this.setState({searchValue: e.target.value});
    }

    render() {
        const {classes, farmData, badgeAlarms, loadedMarker, tipo, myInfo} = this.props;
        const {searchValue} = this.state;
        const title = tipo == 0?`Alarmas activas`:`Histórico alarmas`;

        return (
            <div>
            <Layout me={myInfo} marker={loadedMarker} history={this.props.history} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} onSearchChange={(evt:any)=>this.handleSearchValueChange(evt)} searchValue={this.state.searchValue} placeholderSearch={"Buscar alarma..."} badgeAlarms={badgeAlarms} navBar={farmData?.data.navbar}/>

                    <div className='dashboard-content'>

                        {(farmData && farmData.data.dashboards && (tipo == 0?farmData.data.alarmsA:farmData.data.alarmsH))?
                                <div style={{marginTop:10, marginLeft:10, marginRight:10}}>
                                    <div style={{marginBottom:30}}>
                                        <strong style={{fontSize:'18px'}}>{`${title}`}</strong>
                                        <p>
                                            {`Sección donde podrás visualizar tus alarmas.`}
                                        </p>
                                    </div>
                                    <div>
                                        {this.RenderAlarmTable(farmData, tipo)}
                                    </div>
                                </div>
                            :
                                'Cargando...'
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);