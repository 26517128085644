import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IMarker, IMe, IObjects } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import {CheckBoxField} from './FormsWidgets';
import {getNumeric} from '../vc_general';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

function verifyPhone(phone:string){
    let newPhone = getNumeric(phone);
    if(newPhone.length == 11)
        return true;
    return false;
}

interface INotificacionesProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface INotificacionesState {
    me?:IMe;
}

class Notificaciones extends Component<INotificacionesProps, INotificacionesState> {
    constructor(props: INotificacionesProps) {
        super(props);
        this.state = {
            me: props.myInfo
        };
    }

    componentDidUpdate(prevprops:INotificacionesProps){
        if(prevprops.myInfo !== this.props.myInfo){
            this.setState({me:this.props.myInfo})
        }
    }

    handleFieldChange=(id:string, evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[id] = evt.target.checked;
            this.setState({me:newMe});
        }
    }

    handleFieldTextChange=(evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            if(evt.target.id == "phone")
                newMe[evt.target.id] = getNumeric(evt.target.value);
            else
                newMe[evt.target.id] = evt.target.value;
            this.setState({me:newMe});
        }
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {me} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {me?
                            <div style={{marginTop:10, marginLeft:10}}>
                                <div style={{marginBottom:30}}>
                                    <strong style={{fontSize:'18px'}}>{`Notificaciones`}</strong>
                                    <p>
                                        <a>{`Si prefieres estar informado a través de la plataforma de `}</a>
                                        <a style={{color:'#98CCA1', fontWeight:'bold'}}>{`whatsapp`}</a>
                                        <a>{`, puedes darnos permiso para poder enviarte las notificaciones.`}</a>
                                    </p>
                                </div>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <img height={'30px'} src={WhatsIcon}/>
                                                </td>
                                                <td style={{fontSize:'13px'}}>
                                                    {`Número de teléfono`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <input id={`phone`} onChange={(evt:any)=>this.handleFieldTextChange(evt)} style={{padding:10, border: !verifyPhone(me.phone)?'1px solid red':'1px solid black',outlineColor: !verifyPhone(me.phone)?'red':'black'}} height={'30px'} defaultValue={me.phone}/>
                                                </td>
                                                <td style={{paddingLeft:10}}>
                                                    <CheckBoxField
                                                        id={`allowWhatsapp`}
                                                        name={`Permiso`}
                                                        fieldsData={me}
                                                        disabled={false}
                                                        handleFieldChange={this.handleFieldChange}
                                                        shrink={false}
                                                        size={"small"}
                                                        type={"boolean"}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} style={{fontSize:'11px'}}>
                                                    {`Ej: +34 XXX XXX XXX`}
                                                </td>
                                            </tr>
                                            <tr>
                                                {(myInfo == me || !verifyPhone(me.phone))?
                                                    null
                                                :
                                                    <td colSpan={2} style={{fontSize:'11px'}}>
                                                        <button className={classes.buttonSave} onClick={()=>updateMe(me, 1)}>{`Guardar`}</button>
                                                    </td>
                                                }
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);