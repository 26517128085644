import React from 'react';
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function GetNode(list:any, classes:any, handleClick:Function, state:any, levels:number, handleItemClick:Function){

  let level:number = levels + 1;

  let nene = list.children.map((item:any, index:number) => {
    return (
      <div key={item.id}>
        {item.children != null ? (
          <div key={item.id}>
              <ListItem
                  button
                  key={item.id}
                  onClick={(e:any)=>handleClick(
                    e,
                    item
                )}
              >
                <ListItemText
                    primary={item.description}
                    style={{color:'#fff'}}
                />
                  {state[item.Iidd] ? (
                    <ExpandLess style={{color:'#fff'}} />
                  ) : (
                    <ExpandMore style={{color:'#fff'}} />
                  )}
              </ListItem>
              <Collapse
                  key={list.children.id}
                  component="li"
                  in={state[item.id]}                        
                  timeout="auto"
                  unmountOnExit
              >
                <List disablePadding style={{paddingLeft: `${20*level}px`}}>
                    {GetNode(item, classes, handleClick, state, level, handleItemClick)}
                </List>
              </Collapse>{" "}
          </div>
        ) : (
            <ListItem
              button
              onClick={(e:any)=>handleItemClick(
                e,
                item,
                1
              )}
              key={item.id}
            >
              <ListItemText
                  style={{color:'#fff'}}
                  primary={item.description}
              />
            </ListItem>
        )}
      </div>
    );
})
return nene;
}

function BuildNavBar(items:any, classes:any, handleClick:Function, state:any, handleItemClick:Function){
  //console.log(items)
  return(
    <div>
      {items.map((list:any) => {
          return (
              <List
                  key={list.id}
                  
                  subheader={
                      <ListSubheader style={{color:'#fff', fontWeight:'bold'}} >{list.description}</ListSubheader>
                  }
              >
                  {GetNode(list, classes, handleClick, state, 0, handleItemClick)}
                  <Divider key={list.id} absolute style={{backgroundColor:'#fff'}}/>
              </List>
          );
      })}
      {items && items.length > 0?
        <ListItem
          button
          onClick={(e:any)=>handleItemClick(
            e,
            items[0],
            2
          )}
        >
          <ListItemText
              style={{color:'#fff'}}
              primary={`Histórico datos`}
          />
        </ListItem>
      :
        null
      }
  </div>
  );
}

interface INestedListProps {
  classes: any;
  items:any;
  onClick: Function;
}

class NestedList extends React.Component<INestedListProps, any> {
  state:any = {};
  handleClick = (e:any, item:any) => {
      this.setState({ [item.id]: !this.state[item.id] });
  };

  render() {
    const { classes, items, onClick } = this.props;
    return (
      <div>
        {BuildNavBar(items, classes, this.handleClick, this.state, onClick)}

      </div>
    );
  }
}
export default withStyles(styles)(NestedList);