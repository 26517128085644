import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ICheckboxesTagsProps {
  handleChangeSelect:Function;
  options: any;
}

export default function CheckboxesTags(props:ICheckboxesTagsProps) {


  return (
    <Autocomplete
      multiple
      limitTags={1}
      id="checkboxes-tags-demo"
      options={props.options}
      disableCloseOnSelect
      onChange={(e:any, data:any)=>props.handleChangeSelect(e, data)}
      getOptionLabel={(option:any) => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      style={{ maxWidth: '75vw' }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Dispositivos" placeholder="Dispositivo" />
      )}
    />
  );
}