import { IExploitation, IData, IMarker } from '../types';

let OnlineValues:Array<IData>|undefined = undefined;
let HistoricoValues:Array<IData> = [];
let currentData:Array<IData> = [];
let exploitationData:IExploitation = {marker:undefined, data: {navbar:undefined, dashboards:undefined, alarmsA: undefined, alarmsH: undefined}};
let map:Array<IMarker>|undefined = undefined;

class appData {
    static getMap(){
        return map;
    }

    static setMap(newMap:Array<IMarker>){
        map = newMap;
    }

    static getOnlineValues(){
        return OnlineValues;
    }

    static setOnlineValues(newOnlineValues:Array<IData>){
        OnlineValues=newOnlineValues;
    }

    static getHistoricoValues(){
        return HistoricoValues;
    }

    static setHistoricoValues(newHistoricoValues:Array<IData>){
        HistoricoValues=newHistoricoValues;
    }

    static getCurrentData(){
        return currentData;
    }

    static setCurrentData(newData:Array<IData>){
        currentData=newData;
    }

    static getExploitationData(){
        return exploitationData;
    }

    static setExploitationData(newExploitationData:IExploitation){
        exploitationData=newExploitationData;
    }
}
export default appData;