import React, { Component } from 'react';
import './App.css';
import { Redirect, Route, Switch } from "react-router-dom";
import { IAlarm, IData, IExploitation, IMarker, IMe, IObjects } from './types';
import Home from './Components/Home';
import Dashboard from './Components/Dashboard';
import Notificaciones from './Components/Notificaciones';
import News from './Components/News';
import Partes from './Components/Partes';
import Alarms from './Components/Alarms/Alarms';
import RegisterDevice from './Components/RegisterDevice';
import FindDevice from './Components/FindDevice';
import RegisterUser from './Components/RegisterUser';
import Historic from './Components/Historic';
import GeneralSettings from './Components/GeneralSettings';
import OutOfService from './Components/OutOfService';
import Unauthorized from './Components/Unauthorized';
import OrderService from './ApiClients/OrderService';
import appData from './ApiClients/appData';
import {removeItemLocalStorage, findAndModifyFirst} from './vc_general';
import './Components/Home.css';
import './Components/Dashboard.css';
import './Components/FindDevice.css';
import './Components/Layout.css';
import './Components/CreateDialog.css';
import { cloneDeep } from 'lodash';

let timerGetData:any = undefined;

let version:string = 'v5.2.2';

const farm = {marker:undefined, data: {navbar:undefined, dashboards:undefined, alarmsA: undefined, alarmsH: undefined}};

interface IAppProps {
  history: any;
  match:any;
}

interface IAppState {
  myInfo?: IMe;
  markers:Array<IMarker>;
  loadedMarker?:IMarker;
  farmData:IExploitation;
  loading:boolean;
  loadingmap:boolean;
  dataDevices?:Array<IData>;
  ninformed: boolean;
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      myInfo: undefined,
      markers:[],
      loading:false,
      loadingmap:true,
      farmData: farm,
      ninformed: false
    };
  }

  componentWillMount(){
    // Evita que el usuario pueda hacer zoom
    /*document.addEventListener('touchmove', function (event:any) {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false });*/

    this.getMyInfo();
    this.resetLocalStorage();
  }

  componentDidMount(){
    //this.loadMap();
  }

  resetLocalStorage=()=>{
    removeItemLocalStorage("searchFarm");
    removeItemLocalStorage("mapfilters");

  }

  loadMap=async()=>{
    let response = await OrderService.loadMap();
    
    if(response){
      this.setState({markers:response,loadingmap:false});
    } else {
      this.setState({loadingmap:false});
    }

    setTimeout(() => {
      this.loadMap();
    }, 300000);
  }

  changeExploitation=(marker:IMarker, refresh:boolean, task: number)=>{
    if(refresh){
      this.setState({ farmData:farm, loading:true}, ()=>this.loadExploitationData(marker, refresh, task));
    } else{
      const {loadedMarker} = this.state;
      if(loadedMarker == marker)
        return;
      this.setState({farmData:farm, dataDevices: undefined, loadedMarker:undefined, loading:true}, ()=>this.loadExploitationData(marker, false, task));  
    }
  }

  loadDataFarm=async(marker?:IMarker)=>{
    clearTimeout(timerGetData);

    const {loadedMarker} = this.state;

    if(marker){
      let data:any = await OrderService.loadDataBlob(marker.farmId, marker.dataareaid, true);
      this.setState({dataDevices:data})
    } else if(loadedMarker){
      let data:any = await OrderService.loadDataBlob(loadedMarker.farmId, loadedMarker.dataareaid, true);
      this.setState({dataDevices:data})
    }

    timerGetData = setTimeout(() => {
      this.loadDataFarm()
    }, 300000);
  }

  loadExploitationData=async(marker:IMarker, force:boolean, task:number)=>{
    if(!marker)
      return;
    
    const {farmData, dataDevices} = this.state;
    //const refresh = this.state.loadedMarker == marker?false:true;

    let promises = [];

    if(!farmData.data.dashboards || task == 0)
      promises.push(OrderService.LoadObjects(marker, (!farmData.data.dashboards || force)?true:false));

    if(task == 1)
      promises.push(OrderService.LoadAlarms(marker, 0, (!farmData.data.alarmsA || !farmData.data.alarmsA || force)?true:false));

    if(task == 2)
      promises.push(OrderService.LoadAlarms(marker, 1, (!farmData.data.alarmsH  || !farmData.data.alarmsH || force)?true:false));
    
    if(!dataDevices)
      this.loadDataFarm(marker);

    //promises.push(OrderService.LoadObjects(marker, refresh||force?true:false));
    //promises.push(OrderService.LoadObjects(marker, force?true:false));
    //promises.push(OrderService.loadDataBlob(exploitationId));
    Promise.all(promises)
    .then(result=>{
      let expln = cloneDeep(this.state.farmData);
      expln.marker = marker;

      if(!farmData.data.dashboards || task == 0) {
        //console.log(result)
        expln.data.navbar = result[0].navbar;
        expln.data.dashboards = result[0].dashboards;
      }

      if(task == 1)
        expln.data.alarmsA = result[result.length-1];

      if(task == 2)
        expln.data.alarmsH = result[result.length-1];

      appData.setExploitationData(expln);
        /*if(!expl){
            return;
        }*/
      this.setState({farmData:expln, loadedMarker:marker,loading:false});
    })
    .catch(err=>{
        console.log(err);
        this.setState({loading:false});
    })
    .finally(() =>{
    });
  }

  metrics = async (metricType:number) => { // Registra el acceso del usuario, el fallo no autorizado y el fallo por mantenimiento
    await OrderService.metricService(metricType);
  }

  getMyInfo = async () => {
    let response = await OrderService.me();
    this.metrics(1); // Guarda el registro del primer Login
    this.checkUserPermission(response);
  }
    
  checkUserPermission=(inputInfo:IMe|undefined)=>{ // Verifica los permisos del usuario //

    if(inputInfo !== undefined) {
      if(!inputInfo.activated) { // Actualiza el estado MyInfo en caso de haber obtenido correctamente la información del usuario
        this.setState({ myInfo: undefined });
        this.props.history.push('/noAutorizado');
        this.metrics(2);
      } else {
        this.setState({ myInfo: inputInfo });
      }
    } else if(inputInfo == undefined){
      this.props.history.push('/noAutorizado');
      this.metrics(2);
    } else {
      this.props.history.push('/noAutorizado');
    }
  }

  updateDashboard=(object:Array<IObjects>)=>{
    const d = appData.getExploitationData();
    if(d){
      let nDashboard = d;

      for (let index = 0; index < object.length; index++) {
        const nd = findAndModifyFirst(d.data.dashboards, object[index], object[index]);

        if(nd[0]){
          nDashboard.data.dashboards = nd[1];
        }        
      }
      
      appData.setExploitationData(d);
      OrderService.saveData(object, 9);
    }
  }

  updateMe = (me:IMe, action:number)=>{
    this.setState({myInfo: me}, ()=>OrderService.saveData(me, action));
  }

  updateNinformed = ()=>{
    this.setState({ninformed: true});
  }

  render() {
    return (
        <Switch>
          <Route
          exact
          path="/"
          render={(props) =>
            <Home
              {...props}
              myInfo={this.state.myInfo}
              version={version}
              markers={this.state.markers}
              farmData={this.state.farmData}
              loading={this.state.loading}
              loadingmap={this.state.loadingmap}
              loadedMarker={this.state.loadedMarker}
              loadMap={()=>this.loadMap()}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.changeExploitation(marker, force, task)}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
              updateNinformed={this.updateNinformed}
              ninformed={this.state.ninformed}
            />
          }
        />
        <Route
          exact
          path="/:entityDataareaid/:entityFarmId/dashboard/:dashboard"
          render={(props) =>
            <Dashboard
              {...props}
              myInfo={this.state.myInfo}
              farmData={this.state.farmData}
              loading={this.state.loading}
              loadedMarker={this.state.loadedMarker}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.loadExploitationData(marker, force, task)}
              updateDashboard={this.updateDashboard}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
              dataDevices={this.state.dataDevices}
          />
          }
        />
        <Route
          exact
          path="/notifications"
          render={(props) =>
            <Notificaciones
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/news"
          render={(props) =>
            <News
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/generalsettings"
          render={(props) =>
            <GeneralSettings
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/:entityDataareaid/:entityFarmId/historic"
          render={(props) =>
            <Historic
              {...props}
              myInfo={this.state.myInfo}
              farmData={this.state.farmData}
              loading={this.state.loading}
              loadedMarker={this.state.loadedMarker}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.loadExploitationData(marker, force, task)}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
              dataDevices={this.state.dataDevices}
          />
          }
        />
        <Route
          exact
          path="/iotdevice/register"
          render={(props) =>
            <RegisterDevice
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/iotdevice/sigin"
          render={(props) =>
            <RegisterDevice
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/iotdevice/find"
          render={(props) =>
            <FindDevice
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/invitations"
          render={(props) =>
            <RegisterUser
              {...props}
              farmData={this.state.farmData}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/:entityDataareaid/:entityFarmId/alarms/actives"
          render={(props) =>
            <Alarms
              {...props}
              tipo={0}
              myInfo={this.state.myInfo}
              farmData={this.state.farmData}
              loadedMarker={this.state.loadedMarker}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.loadExploitationData(marker, force, task)}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/:entityDataareaid/:entityFarmId/alarms/historic"
          render={(props) =>
            <Alarms
              {...props}
              tipo={1}
              myInfo={this.state.myInfo}
              farmData={this.state.farmData}
              loadedMarker={this.state.loadedMarker}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.loadExploitationData(marker, force, task)}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
          />
          }
        />
        <Route
          exact
          path="/:entityDataareaid/:entityFarmId/partes"
          render={(props) =>
            <Partes
              {...props}
              myInfo={this.state.myInfo}
              loading={this.state.loading}
              updateMe={this.updateMe}
              farmData={this.state.farmData}
              loadedMarker={this.state.loadedMarker}
              badgeAlarms={this.state.loadedMarker?this.state.loadedMarker.alarms:0}
              loadExploitationData={(marker:IMarker, force:boolean, task:number)=>this.loadExploitationData(marker, force, task)}
          />
          }
        />
        <Route
          exact
          path="/noAutorizado"
          render={(props) =>
            <Unauthorized
              {...props}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          exact
          path="/fueraServicio"
          render={(props) =>
            <OutOfService
              {...props}
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Redirect from="/" to="/" />
      </Switch>
    );
  }
}

export default App;
