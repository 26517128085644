import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, IObjects, IExploitation, IData, IIData, IDevice, IBattery, IMarker, IDownload } from '../types';
import CreateModal from './CreateDialog'
import OrderService from '../ApiClients/OrderService';
import Layout from './Layout';
import {cloneDeep,groupBy,filter, uniqBy, findIndex} from 'lodash';
import { Bar, Line } from 'react-chartjs-2';
import IconButton from '@material-ui/core/IconButton';
import Loader from './Loader';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import ListDevices from './Materials/MultiSelect';
import LoaderBeauty from './Materials/activityProgress';
import DatePicker from './Materials/DatePicker';
import ReactExport from 'react-export-excel';
import {DatasetDataFarm02, DatasetDataFarm03, DatasetDataFarm04, DatasetDataFarm05, findAllDevices} from '../helpers';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
        nested: {
        paddingLeft: theme.spacing(4),
    },
});

const exportDataExcel=(data:Array<IData>)=>{
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const grouped = groupBy(data, 'dsCode');

    let sheets = Object.keys(grouped).map((key:string,index:number)=>{
        return(
            <ExcelSheet data={grouped[key]} name={key} key={index}>
                <ExcelColumn label="dsCode" value="dsCode"/>
                <ExcelColumn label="dtRegister" value="dtRegister"/>
                <ExcelColumn label="TENDVALUE" value="TENDVALUE"/>
                <ExcelColumn label="RATIOTENDVALUE" value="RATIOTENDVALUE"/>
                <ExcelColumn label="ACUMVALUE" value="ACUMVALUE"/>
                <ExcelColumn label="RATIOACUMVALUE" value="RATIOACUMVALUE"/>
                <ExcelColumn label="factor" value="factor"/>
                <ExcelColumn label="farmId" value="farmId"/>
                <ExcelColumn label="dataareaid" value="dataareaid"/>
            </ExcelSheet>
        );
    });

    return (
        <ExcelFile hideElement={true} element={<button>{`Descargar`}</button>}>
            {sheets}
        </ExcelFile>
    );
}

interface IFormDownloadDataProps {
    //classes: any;
    devices?:Array<IDevice>;
}

interface IFormDownloadDataState {
    form:IDownload;
    downloaddata?:Array<IData>;
    downloading:boolean;
}

class IFormDownloadData extends Component<IFormDownloadDataProps, IFormDownloadDataState> {
    constructor(props: IFormDownloadDataProps) {
        super(props);
        this.state = {
            form: {
                iniDate: new Date(),
                endDate: new Date()
            },
            downloading:false
        };
    }
    
    downloadData=async(form:IDownload)=>{
        let ret:any = await OrderService.downloadData(form);
        this.setState({downloaddata:ret, downloading:false});
    }

    handleChangeUser=(id:any, event:any)=>{

        let newForm = Object.assign({}, this.state.form);
        newForm[id] = event.target.value;        
        this.setState({ form: newForm });
    }
    
    handleChangeDate=(id:any, event:any)=>{
        let newForm = Object.assign({}, this.state.form);
        newForm[id] = event;        
        this.setState({ form: newForm, downloaddata: undefined });
    }

    verifyForm=(id?:string)=>{
        const item = this.state.form;
        return true;
    }

    shouldDisableToday = (date:any, enddate:Date) => {
        if(date.getTime() > enddate)
            return true
        return false;
    }

    shouldDisableEndDate = (date:any) => {
        if(date.getTime() > new Date())
            return true
        return false;
    }

    tickDownloadData=()=>{
        let nform = cloneDeep(this.state.form);
        nform.devices = this.props.devices;
        this.setState({downloading:true},()=>this.downloadData(nform));
    }

    render() {
        const {form} = this.state;
        return (
            <div style={{padding:10, border:'2px solid #ddd', borderRadius:'10px'}}>
                <div>
                    {DatePicker('iniDate', 'Fecha inicio', "dd/MM/yyyy", this.handleChangeDate, form.iniDate, false, (date:any)=>this.shouldDisableToday(date, form.endDate), new Date(new Date().setDate(form.endDate.getDate() - 365)))}
                </div>
                <div style={{marginTop:20}}>
                    {DatePicker('endDate', 'Fecha fin', "dd/MM/yyyy", this.handleChangeDate, form.endDate, false, this.shouldDisableEndDate, form.iniDate)}
                </div>
                <div style={{marginTop:20, textAlign:'center'}}>
                    <div>
                        {!this.state.downloading?
                            !this.state.downloaddata?
                                <button className="button-save" onClick={()=>this.tickDownloadData()}>{`Descargar`}</button>
                            :
                                null
                        :
                            `Descargando datos...`
                        }
                    </div>
                    <div style={{marginTop:20}}>
                        {this.state.downloaddata && !this.state.downloading?
                            this.state.downloaddata.length == 0?
                                `No hay datos disponibles.`
                            :
                                exportDataExcel(this.state.downloaddata)
                        :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
const FormDownloadData = withStyles(styles)(IFormDownloadData);

interface IHistoricProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    farmData?:IExploitation;
    loadExploitationData:Function;
    loading:boolean;
    badgeAlarms:number;
    dataDevices?:Array<IData>;
    loadedMarker?:IMarker;
}

interface IHistoricState {
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    searchValue:string;
    objectSelected?:IObjects;
    object2Delete?:IObjects;
    editing: boolean;
    loadingbackground:boolean;
    availableDevices?: Array<IDevice>;
    datafarm02?: Array<IIData>;
    idatafarm02: number;
    datafarm03?: Array<IIData>;
    idatafarm03: number;
    datafarm04?: Array<IIData>;
    idatafarm04: number;
    datafarm05?: Array<IIData>;
    idatafarm05: number;
    dashboardDevices?: Array<IObjects>;
}

class Historic extends Component<IHistoricProps, IHistoricState> {
    _listdevices:Array<IDevice> = [];

    constructor(props: IHistoricProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            searchValue:'',
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            editing: false,
            loadingbackground:false,
            idatafarm02: 0,
            idatafarm03: 0,
            idatafarm04: 0,
            idatafarm05: 0
        };
    }

    componentDidMount(){
        this.loadPage(false);
        this.loadDevices();
    }

    componentDidUpdate(prevprops:IHistoricProps){
        if(prevprops.farmData !== this.props.farmData){
            this.setState({loadingbackground:false, dashboardDevices: findAllDevices(this.props.farmData?.data.dashboards)});
        }
    }

    loadPage = async (refresh:boolean) => {
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);        
        const marker = await OrderService.getMarker(entityFarmId,entityDataareaid);

        if(marker){
            this.props.loadExploitationData(marker, refresh, 0);
            this.loadDataFarm02(marker);
            this.loadDataFarm03(marker);
            this.loadDataFarm04(marker);
            this.loadDataFarm05(marker);
        }
    }

    loadDevices=async()=>{
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid); 

        let devices:Array<IDevice> = await OrderService.loadAvailableDevices(entityDataareaid, entityFarmId)
        if(!devices){
            this.setState({availableDevices:[]});
        } else {
            devices = devices.map((device:IDevice, index:number)=>{
                return {
                    ...device,
                    name: `${device.tipo} - ${device.description} - ${device.referenceId?device.referenceId:device.dsCode}`,
                    id: device.id
                }
            })
            this.setState({availableDevices:devices});
        }
    }

    loadDataFarm02=async(marker:IMarker)=>{

        let data:any = await OrderService.loadDataBlob02(marker.dataareaid, marker.farmId);
        if(data) {
            this.setState({datafarm02: data});
        } else {
            console.log('No data');
        }

    }

    loadDataFarm03=async(marker:IMarker)=>{

        let data:any = await OrderService.loadDataBlob03(marker.dataareaid, marker.farmId);
        if(data) {
            this.setState({datafarm03: data});
        } else {
            console.log('No data');
        }

    }

    loadDataFarm04=async(marker:IMarker)=>{

        let data:any = await OrderService.loadDataBlob04(marker.dataareaid, marker.farmId);
        if(data) {
            this.setState({datafarm04: data});
        } else {
            console.log('No data');
        }

    }

    loadDataFarm05=async(marker:IMarker)=>{

        let data:any = await OrderService.loadDataBlob05(marker.dataareaid, marker.farmId);
        if(data) {
            this.setState({datafarm05: data});
        } else {
            console.log('No data');
        }

    }

    timeoutManageLoadingBackground = () => {
        setTimeout(() => {
            if(this.state.loadingbackground)
                this.setState({loadingbackground:false});
        }, 10000);
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleDownloadClick = () => {
        this.setState({
            creationModalOpen:true,
            closeButtonModal:true,
            modalType:-1,
            typeAction: -1,
            titleModal: `Centro de descarga`,
            inputText: this._listdevices.length == 0?
                    `Debe seleccionar algún dispositivo.`
                :
                    /*comeFromHubCenter()?
                        `No puede descargar los datos desde Nealia Hub en estos momentos, estamos trabajando para que pueda hacerlo en breve.`
                    :*/
                    <FormDownloadData devices={this._listdevices}/>,
            fullScreenModal:false,
        });
        //this.setState({ [item.id]: !this.state[item.id] });
    };

    handleChangeSelectDevices=(e:any, list:Array<IDevice>)=>{
        this._listdevices = list;
    }

    renderDashboard=()=>{
        //const dashboard = this.props.match.params.dashboard;
        const {availableDevices} = this.state;
        return(
            <div style={{marginTop:10, marginLeft:10}}>
                <div style={{marginBottom:5}}>
                    <strong style={{fontSize:'18px'}}>{`Histórico datos`}</strong>
                    <p>
                        {`Aquí podrás visualizar o descargar el histórico de datos.`}
                    </p>
                </div>
                <div style={{width:'100%', display:'flex'}}>

                    <div style={{width:'78%'}}>
                        <ListDevices options={availableDevices?availableDevices:[]} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                    </div>
                    <IconButton onClick={()=>this.handleDownloadClick()} style={{marginLeft:5}} size="medium" aria-label="Usuario">
                        <DownloadIcon />
                    </IconButton>
                </div>
            </div>
        );
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, objectSelected: undefined });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            default:
                break;
        }
    }

    handleSearchValueChange=(e:any)=>{
        this.setState({searchValue: e.target.value});
    }

    handleDataFarm02Change=(next:number)=>{
        const {datafarm02, idatafarm02} = this.state;

        if(datafarm02){
            const len = uniqBy(datafarm02,'dsCode').length;
            let nidx = idatafarm02 + next;
            if(nidx >= len)
                nidx = 0;
            else if(nidx < 0)
                nidx = (len - 1)
            this.setState({idatafarm02: nidx});
        }
    }

    renderDataFarm02 = () => {
        const {datafarm02, idatafarm02, dashboardDevices} = this.state;

        if(datafarm02 && dashboardDevices){
            if(datafarm02.length == 0)
                return(`Sin datos de temperatura en esta granja.`);

            let ds = uniqBy(datafarm02,'dsCode')[idatafarm02].dsCode;
            let dsname = ds;

            let ids = findIndex(dashboardDevices, function(o){return o.dsCode == ds});
            if(ids > -1)
                dsname = dashboardDevices[ids].description;
                
            return(
                <div style={{maxHeight:500}}>
                    <IconButton onClick={()=>this.handleDataFarm02Change(-1)} style={{marginLeft:5}} size="small" aria-label="BackDevice">
                        <BackIcon />
                    </IconButton>
                    <strong>{dsname}</strong>
                    <IconButton onClick={()=>this.handleDataFarm02Change(1)} style={{marginLeft:5}} size="small" aria-label="NextDevice">
                        <NextIcon />
                    </IconButton>
                    <Line options={{responsive: true, maintainAspectRatio: false}} data={DatasetDataFarm02(filter(datafarm02, function(o){return o.dsCode == ds}))}/>
                </div>
            );
        }

        return(<div>{`Cargando resumen temperatura...`}</div>);
    }

    handleDataFarm03Change=(next:number)=>{
        const {datafarm03, idatafarm03} = this.state;

        if(datafarm03){
            const len = uniqBy(datafarm03,'dsCode').length;
            let nidx = idatafarm03 + next;
            if(nidx >= len)
                nidx = 0;
            else if(nidx < 0)
                nidx = (len - 1)
            this.setState({idatafarm03: nidx});
        }
    }

    renderDataFarm03 = () => {
        const {datafarm03, idatafarm03, dashboardDevices} = this.state;

        if(datafarm03 && dashboardDevices){
            if(datafarm03.length == 0)
                return(`Sin datos de contadores de agua en esta granja.`);

            const ds = uniqBy(datafarm03,'dsCode')[idatafarm03].dsCode;
            let dsname = ds;

            let ids = findIndex(dashboardDevices, function(o){return o.dsCode == ds});
            if(ids > -1)
                dsname = dashboardDevices[ids].description;

            return(
                <div style={{maxHeight:500}}>
                    <IconButton onClick={()=>this.handleDataFarm03Change(-1)} style={{marginLeft:5}} size="small" aria-label="BackDevice">
                        <BackIcon />
                    </IconButton>
                    <strong>{dsname}</strong>
                    <IconButton onClick={()=>this.handleDataFarm03Change(1)} style={{marginLeft:5}} size="small" aria-label="NextDevice">
                        <NextIcon />
                    </IconButton>
                    <Bar options={{responsive: true, maintainAspectRatio: false, scales: {yAxes: [{ticks: { display: true, beginAtZero:true } }] },}} data={DatasetDataFarm03(filter(datafarm03, function(o){return o.dsCode == ds}))}/>
                </div>
            );
        }

        return(<div>{`Cargando resumen contadores...`}</div>);
    }

    handleDataFarm04Change=(next:number)=>{
        const {datafarm04, idatafarm04} = this.state;

        if(datafarm04){
            const len = uniqBy(datafarm04,'dsCode').length;
            let nidx = idatafarm04 + next;
            if(nidx >= len)
                nidx = 0;
            else if(nidx < 0)
                nidx = (len - 1)
            this.setState({idatafarm04: nidx});
        }
    }

    renderDataFarm04 = () => {
        const {datafarm04, idatafarm04, dashboardDevices} = this.state;

        if(datafarm04 && dashboardDevices){
            if(datafarm04.length == 0)
                return(`Sin datos de silos en esta granja.`);

            const ds = uniqBy(datafarm04,'dsCode')[idatafarm04].dsCode;
            let dsname = ds;

            let ids = findIndex(dashboardDevices, function(o){return o.dsCode == ds});
            if(ids > -1)
                dsname = dashboardDevices[ids].description;

            return(
                <div style={{maxHeight:500}}>
                    <IconButton onClick={()=>this.handleDataFarm04Change(-1)} style={{marginLeft:5}} size="small" aria-label="BackDevice">
                        <BackIcon />
                    </IconButton>
                    <strong>{dsname}</strong>
                    <IconButton onClick={()=>this.handleDataFarm04Change(1)} style={{marginLeft:5}} size="small" aria-label="NextDevice">
                        <NextIcon />
                    </IconButton>
                    <Bar options={{responsive: true, maintainAspectRatio: false}} data={DatasetDataFarm04(filter(datafarm04, function(o){return o.dsCode == ds}))}/>
                </div>
            );
        }

        return(<div>{`Cargando resumen silos...`}</div>);
    }

    handleDataFarm05Change=(next:number)=>{
        const {datafarm05, idatafarm05} = this.state;

        if(datafarm05){
            const len = uniqBy(datafarm05,'dsCode').length;
            let nidx = idatafarm05 + next;
            if(nidx >= len)
                nidx = 0;
            else if(nidx < 0)
                nidx = (len - 1)
            this.setState({idatafarm05: nidx});
        }
    }

    renderDataFarm05 = () => {
        const {datafarm05, idatafarm05, dashboardDevices} = this.state;

        if(datafarm05 && dashboardDevices){
            if(datafarm05.length == 0)
                return(`Sin datos de humedad en esta granja.`);

            const ds = uniqBy(datafarm05,'dsCode')[idatafarm05].dsCode;
            let dsname = ds;

            let ids = findIndex(dashboardDevices, function(o){return o.dsCode == ds});
            if(ids > -1)
                dsname = dashboardDevices[ids].description;

            return(
                <div style={{maxHeight:500}}>
                    <IconButton onClick={()=>this.handleDataFarm05Change(-1)} style={{marginLeft:5}} size="small" aria-label="BackDevice">
                        <BackIcon />
                    </IconButton>
                    <strong>{dsname}</strong>
                    <IconButton onClick={()=>this.handleDataFarm05Change(1)} style={{marginLeft:5}} size="small" aria-label="NextDevice">
                        <NextIcon />
                    </IconButton>
                    <Line options={{responsive: true, maintainAspectRatio: false}} data={DatasetDataFarm02(filter(datafarm05, function(o){return o.dsCode == ds}))}/>
                </div>
            );
        }

        return(<div>{`Cargando resumen humedad...`}</div>);
    }

    render() {
        const {farmData, badgeAlarms, classes, loadedMarker, myInfo} = this.props;
        const {searchValue, loadingbackground} = this.state;

        return (
            <div>
            <Layout me={myInfo} marker={loadedMarker} history={this.props.history} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} onSearchChange={undefined} searchValue={this.state.searchValue} placeholderSearch={"Buscar dispositivo..."} badgeAlarms={badgeAlarms} navBar={farmData?.data.navbar}/>

                    <div className='dashboard-content'>

                        {farmData?
                            <div>
                                {this.renderDashboard()}
                                <div style={{marginTop:20, marginLeft:10, marginRight:10, border:'2px solid #003B71', padding:10, borderRadius:20}}>
                                    <p><strong>{`Resumen temperatura`}</strong></p>
                                    {this.renderDataFarm02()}
                                </div>
                                <div style={{marginTop:20, marginLeft:10, marginRight:10, border:'2px solid #003B71', padding:10, borderRadius:20}}>
                                    <p><strong>{`Resumen humedad`}</strong></p>
                                    {this.renderDataFarm05()}
                                </div>
                                <div style={{marginTop:20, marginLeft:10, marginRight:10, border:'2px solid #003B71', padding:10, borderRadius:20}}>
                                    <p><strong>{`Resumen agua`}</strong></p>
                                    {this.renderDataFarm03()}
                                </div>
                                <div style={{marginTop:20, marginLeft:10, marginRight:10, border:'2px solid #003B71', padding:10, borderRadius:20}}>
                                    <p><strong>{`Resumen silos`}</strong></p>
                                    {this.renderDataFarm04()}
                                </div>
                            </div>
                        :
                            'Cargando...'
                        }
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.inputText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.typeAction}
                                loadingModal={false}
                                titleModal={this.state.titleModal}
                                closeButton={this.state.closeButtonModal}
                                fullscreen={this.state.fullScreenModal}
                                maxwidth={this.state.maxwidthModal}
                            />
                        :
                            null
                        }
                        <LoaderBeauty visible={loadingbackground}/>
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Historic);