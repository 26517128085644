import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IBusiness, IExploitation, IMarker, IMe, IObjects, IRegister } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import { SelectorField, CheckBoxField, TextFieldInput, SwitchField } from './FormsWidgets';
import CreateModal from './CreateDialog'
import OrderService from '../ApiClients/OrderService';

const DeviceForm = {dscode: ``, farmId: ``, naveId: -1, dataareaid: ``, dettach: false, name: ``};

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

interface INotificacionesProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
}

interface INotificacionesState {
    register:IRegister;
    modalType: number;
    typeAction: number;
    inputText: any;
    creationModalOpen: boolean;
    titleModal:string;
    loadingModal:boolean;
    fullScreenModal?:boolean;
    maxwidthModal?:any;
    closeButtonModal:boolean;
    business: Array<IBusiness>;
    loading:boolean;
}

class Notificaciones extends Component<INotificacionesProps, INotificacionesState> {
    constructor(props: INotificacionesProps) {
        super(props);
        this.state = {
            register: DeviceForm,
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false,
            titleModal: 'MENSAJE',
            closeButtonModal: false,
            loadingModal: false,
            business: [],
            loading: true
        };
    }

    componentDidMount(){
        this.loadBusiness();
    }

    loadBusiness=async()=>{
        let business:Array<IBusiness> = await OrderService.LoadBusiness()
        if(!business){
            this.setState({business:[], loading: false});
        } else {
            this.setState({business:business, loading: false});
        }
    }

    RegisterDevice=async(register:IRegister)=> {
        const response = await OrderService.saveData(register, 6);
        let resetForm = false;
        let text = `¡ATENCIÓN! No se ha podido realizar dicha acción.`
        switch(response){
            case 1:
                text = `Dispositivo registrado correctamente.`
                resetForm = true;
                break;
            case 2:
                text = `¡ATENCIÓN! El dispositivo ya ha sido registrado.`
                break;
            case 3:
                text = `¡ATENCIÓN! La granja no existe.`
                break;
            case 4:
                text = `¡ATENCIÓN! El dispositivo no existe.`
                break;
            case 5:
                text = `¡ATENCIÓN! La nave ${register.naveId} en la granja ${register.farmId} no existe.`
                break;
            case 6:
                text = `¡ATENCIÓN! No tienes permisos para desvincular el dispositivo ${register.dscode} ya que no tienes configurada la granja.`
                break;
            case 7:
                text = `Dispositivo desvinculado correctamente.`
                resetForm = true;
                break;
        }
        this.setState({creationModalOpen:true, modalType: 1, loadingModal: false, register: resetForm?DeviceForm:register, inputText: text })
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal implica registrar un nuevo dispositivo
            case 2: // Cuando el modal implica desvincular un dispositivo
                this.setState({loadingModal: true}, ()=>this.RegisterDevice(this.state.register));
                break;
            default:
                break;
        }
    }

    handleChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);
        newRegister[id] = evt.target.value;
        this.setState({register:newRegister});
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    handleRegisterDeviceClick = (register: IRegister) => {
        if(!register.dettach)
            this.setState({
                modalType: 2,
                titleModal: `MENSAJE`,
                closeButtonModal: false,
                inputText: `¡ATENCIÓN! ¿Quieres dar de alta el dispositivo "${register.dscode}" en la granja "${register.farmId}" de la empresa "${register.dataareaid}"?`,
                typeAction: 1,
                creationModalOpen: true
            })
        else
            this.setState({
                modalType: 2,
                titleModal: `MENSAJE`,
                closeButtonModal: false,
                inputText: `¡ATENCIÓN! ¿Quieres desvincular el dispositivo "${register.dscode}"?`,
                typeAction: 2,
                creationModalOpen: true
            })
    }

    handleSwichChangeUser=(id:string, evt:any)=>{
        let newRegister = cloneDeep(this.state.register);
        newRegister[id] = evt.target.checked;
        this.setState({register:newRegister});
    }

    RegisterControl = (register:IRegister) => {
        if(register.dscode.length == 0)
            return false;
        if(register.farmId.length == 0 && !register.dettach)
            return false;
        /*if(register.naveId < 1)
            return false;*/
        if(register.dataareaid.length == 0 && !register.dettach)
            return false;

        return true;
    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, loadedMarker, farmData} = this.props;
        const {register} = this.state;

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {myInfo && !this.state.loading?
                            [50,100].indexOf(myInfo.role) > -1?
                                <div style={{marginTop:10, marginLeft:10}}>
                                    <div style={{marginBottom:20}}>
                                        <strong style={{fontSize:'18px'}}>{`Alta/Baja dispositivos`}</strong>
                                        <p> {`Aquí puedes asociar o desvincular los dispositivos de las granjas.`}</p>
                                    </div>
                                    <div>
                                        <table style={{width:'100%', maxWidth:'400px'}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <SwitchField
                                                            key={0}
                                                            name={`dettach`}
                                                            id={`dettach`}
                                                            fieldsData={register}
                                                            handleFieldChange={this.handleSwichChangeUser}
                                                            disabled={false}
                                                            color="secondary"
                                                        />
                                                        {`Desvincular`}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <TextFieldInput
                                                            key={0}
                                                            name={`Código dispositivo`}
                                                            id={`dscode`}
                                                            fieldsData={register}
                                                            handleFieldChange={this.handleChangeUser}
                                                            size="medium"
                                                            shrink={true}
                                                            type={"text"}
                                                            required={true}
                                                            style={3}
                                                            helper={``}
                                                            maxText={50}
                                                            noError={true}
                                                            disabled={false}
                                                        />
                                                    </td>
                                                </tr>
                                                {!register.dettach?
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Nombre dispositivo`}
                                                                id={`name`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={false}
                                                                style={3}
                                                                helper={``}
                                                                maxText={50}
                                                                noError={true}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <TextFieldInput
                                                                key={0}
                                                                name={`Código granja`}
                                                                id={`farmId`}
                                                                fieldsData={register}
                                                                handleFieldChange={this.handleChangeUser}
                                                                size="medium"
                                                                shrink={true}
                                                                type={"text"}
                                                                required={true}
                                                                style={2}
                                                                helper={``}
                                                                maxText={8}
                                                                noError={true}
                                                                disabled={false}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{paddingTop: '10px'}}>
                                                            <SelectorField
                                                                key={0}
                                                                name={`Empresa`}
                                                                id={`dataareaid`}
                                                                fieldsData={this.state.business}
                                                                handleFieldChange={this.handleChangeUser}
                                                                required={true}
                                                                style={1}
                                                                deviceData={register}
                                                            />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                                    
                                                :
                                                    null
                                                }
                                                {/*<tr>
                                                    <td>
                                                        <TextFieldInput
                                                            key={0}
                                                            name={`Nave`}
                                                            id={`naveId`}
                                                            fieldsData={register}
                                                            handleFieldChange={this.handleChangeUser}
                                                            size="medium"
                                                            shrink={true}
                                                            type={"number"}
                                                            required={true}
                                                            style={3}
                                                            helper={``}
                                                            maxText={2}
                                                            noError={true}
                                                            disabled={false}
                                                        />
                                                    </td>
                                                </tr>*/}
                                                <tr>
                                                    <td colSpan={2} style={{fontSize:'11px'}}>
                                                        {this.RegisterControl(register)?
                                                            <button onClick={()=>this.handleRegisterDeviceClick(register)} className={classes.buttonSave}>
                                                                {!register.dettach?
                                                                    `Registrar`
                                                                :
                                                                    `Desvincular`
                                                                }
                                                            </button>
                                                        :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.creationModalOpen?
                                        <CreateModal
                                            onCloseCreationModal={this.onCloseCreationModal}
                                            isOpen={this.state.creationModalOpen}
                                            inputText={this.state.inputText}
                                            typeModal={this.state.modalType}
                                            action={this.handleCreateModal}
                                            typeAction={this.state.typeAction}
                                            loadingModal={this.state.loadingModal}
                                            titleModal={this.state.titleModal}
                                            closeButton={this.state.closeButtonModal}
                                            fullscreen={this.state.fullScreenModal}
                                            maxwidth={this.state.maxwidthModal}
                                        />
                                    :
                                        null
                                    }
                                </div>
                            :
                                `No tiene permisos para visualizar esta sección.`
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);