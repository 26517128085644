import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconBuild from '@material-ui/icons/BuildOutlined';
import { IFilter, IObjects } from '../types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import {SelectorField, TextFieldInput} from './FormsWidgets';
import { filter } from 'lodash';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            //display: 'flex',
            //alignItems: 'center',
            //width: 'calc(100% - 20px)',
            //margin: 'auto',
            backgroundColor:'transparent',
            borderWidth: 0, 
            borderColor: '#ccc',
            borderStyle: 'solid',
            borderRadius:'10px'
        },
        input01: {
            marginTop:5,
            marginBottom:5,
            marginLeft:5,
            marginRight:5,
            padding: 8,
            width:'80%'
        },
        input02: {
            marginTop:10,
            marginBottom:10,
            padding: 8,
            width:'90%'
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            borderBottomColor:'#BBBBBB',
        },
        dots:{
            display:'inline-block',
            marginLeft:10,
            width:'15px',
            height:'15px',
            borderRadius:'50%'
        }
    }),
);

interface FormFilters {
    cFilters?:IFilter;
    handleApplyFilterClick:Function;
}

export default function FormFilters(props: FormFilters) {
    const classes = useStyles();
    //const {item, onChangeItem} = props;

    const [filters, setFilters] = useState(props.cFilters?props.cFilters:{tipoDevice:-1, onlyAlarms: false, tipo:-1});

    return (
        <div style={{height:'calc(50vh - 20px)', width:'100%'}}>

            <div style={{width:'100%', overflowX:'auto'}} >
            <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{backgroundColor:'rgba(187, 187, 187, 0.3)'}} key={0}>
                            <TableCell size="small" align="left"><strong>{``}</strong></TableCell>
                            <TableCell size="small" align="center"><strong>{``}</strong></TableCell>
                            <TableCell size="small" align="center"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={0}>
                            <TableCell className={classes.divider} align="left">{`Con alarmas`}</TableCell>
                            <TableCell colSpan={2} className={classes.divider} align="center">
                                <Switch
                                    checked={filters.onlyAlarms}
                                    onChange={(evt:any)=>setFilters({tipoDevice:filters.tipoDevice, onlyAlarms: evt.target.checked, tipo:filters.tipo})}
                                    color="secondary"
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key={1}>
                            <TableCell colSpan={3} className={classes.divider} align="center">
                                <SelectorField
                                    key={0}
                                    name={`Tipo dispositivo`}
                                    id={"tipoDevice"}
                                    fieldsData={[{id:-1, name:'Todos'},{id:1, name:'Ambiente'},{id:2, name:'Consumo agua'},{id:3, name:'Silo'}]}
                                    required={false}
                                    style={3}
                                    handleFieldChange={(id:any, evt:any)=>setFilters({tipoDevice:evt.target.value, onlyAlarms:filters.onlyAlarms, tipo:filters.tipo})}
                                    deviceData={filters}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                            <TableCell colSpan={3} className={classes.divider} align="center">
                                <SelectorField
                                    key={0}
                                    name={`Tipo granja`}
                                    id={"tipo"}
                                    fieldsData={[{id:-1, name:'Todos'},{id:1, name:'Cerdo'},{id:2, name:'Pollo'}]}
                                    required={false}
                                    style={3}
                                    handleFieldChange={(id:any, evt:any)=>setFilters({tipoDevice:filters.tipoDevice, onlyAlarms:filters.onlyAlarms, tipo:evt.target.value})}
                                    deviceData={filters}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
            <div style={{position:'absolute', bottom:10}}>
                <button className="button-save" style={{marginTop:'15px', display:'inline'}} onClick={()=>props.handleApplyFilterClick(filters)}>{`Aplicar`}</button>
            </div>
        </div>
    );
}