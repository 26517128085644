import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchField from './SearchField';
import logoVC from '../Assets/nealia_metrics_icon.png';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import Map from '@material-ui/icons/MapOutlined';
import Drawer from './Drawer';
import background from '../Assets/SmartMetrics_banner.png';
import { authContext } from '../adalConfig';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import IOTIcon from '../Assets/iot.png';
import FindIcon from '../Assets/find.png';
import GeneralSettingsIcon from '../Assets/generalsettings.png';
import InviteIcon from '../Assets/InviteUsers.png';
import NovedadesIcon from '../Assets/SmartMetrics_novedades.png';
import CloseSesionIcon from '../Assets/SmartMetrics_cerrarsesión.png';
import { IMarker, IMe } from '../types';
import AppsIcon from '../Assets/SmartMetrics_appsicon.png';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerPaper: {
    position: 'absolute',
    top:143,
    backgroundColor:'#003B71',
  },
});



interface ILayoutProps {
    history:any;
    onItemClick?: any;
    onSearchChange?:any;
    onKeydown?:any;
    handleFilterClick?:any;
    searchValue:string;
    placeholderSearch:string;
    badgeAlarms:number;
    marker?:IMarker;
    navBar?:any;
    me?:IMe;
}

export default function Layout(props: ILayoutProps) {
    const classes = useStyles();
    const [opened, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElEvents, setAnchorElEvents] = React.useState(null);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickEvents = (event:any) => {
        setAnchorElEvents(event.currentTarget);
    };
    const handleCloseEvents = () => {
        setAnchorElEvents(null);
    };

    const handleDrawerOpenClose = () => {
        setOpen(!opened);
    };

    const appLogOut = () => {
        authContext.logOut();
    }

    const handleNealiaHub = () =>{
        window.location.href = "https://hub.nealia.tech/private_aplicaciones"
    }

    const handleExitApp = () =>{
        appLogOut();
    }
    
    const homepage=()=>{
        //writeLocalStorage('lastZoom', 5);
        //writeLocalStorage('lastCoord', JSON.stringify({lat:39.87220110665813,lng:-3.693293998531213}));
        props.history.push('/')
    }
console.log(props.me)
    return(
        <div>
        <div className='background-container'>
            <div className='background-gradient' />
            <div className='image-background' style={{ backgroundImage: `url(${background})` }} />
        </div>
        <div className='header-content'>
            <table style={{width:'100%'}}>
                <tbody>
                    <tr>
                        <td style={{width:'10%'}}>
                            <div style={{ textAlign: 'left' }}>
                                <img style={{cursor:'pointer'}} onClick={()=>homepage()} src={logoVC} alt='SmartMetricsLogo' className='background-logo' />
                            </div>
                        </td>
                        <td style={{width:'80%'}}>
                            <div className={"hide-on-mobile-tablet"} style={{marginBottom:0, textAlign:'center'}}>
                                <strong style={{color:'#FFF'}}>{`TE DAMOS LA BIENVENIDA A NEALIA METRICS`}</strong>
                            </div>
                        </td>
                        <td style={{ paddingBottom:0, textAlign:'right', width:'10%' }}>
                            <div style={{ display:'flex', justifyContent:'center' }}>
                                <IconButton onClick={()=>homepage()} size="small" aria-label="Notificaciones">
                                    <Map style={{fill:'#cc9966'}} />
                                </IconButton>
                                {props.marker?
                                    <div className="vl">
                                        <IconButton onClick={handleClickEvents} size="small" aria-label="Notificaciones">
                                            <Badge badgeContent={props.badgeAlarms} color="error">
                                                <NotificationsIcon style={{fill:'#e6b800'}} />
                                            </Badge>
                                        </IconButton>
                                            <Menu
                                            style={{marginTop:40, marginLeft:-30}}
                                            id="simple-menu"
                                            anchorEl={anchorElEvents}
                                            keepMounted
                                            open={Boolean(anchorElEvents)}
                                            onClose={handleCloseEvents}

                                            >
                                                <div onClick={()=>props.history.push(`/${props.marker?.entityDataareaid}/${props.marker?.entityFarmId}/alarms/actives`)}  style={{display:'flex',marginLeft:20}}>
                                                    <img style={{margin:'auto'}} height={'30px'} />
                                                    <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleCloseEvents}><strong>{`Alarmas activas`}</strong></MenuItem>
                                                </div>
                                                <div onClick={()=>props.history.push(`/${props.marker?.entityDataareaid}/${props.marker?.entityFarmId}/alarms/historic`)} style={{display:'flex',marginLeft:20, marginTop:10, borderTop:'1px solid #BBBBBB'}}>
                                                    <div style={{margin:'auto'}}><img height={'30px'} /></div>
                                                    <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleCloseEvents}><strong>{`Histórico alarmas`}</strong></MenuItem>
                                                </div>
                                            </Menu>
                                    </div>
                                        
                                    :
                                        null
                                }
                                <div className="vl"></div>
                                <IconButton  onClick={handleClick} style={{marginLeft:5}} size="small" aria-label="Usuario">
                                    <UserIcon style={{fill:'#66ccff'}}/>
                                </IconButton>
                                    <Menu
                                    style={{marginTop:40}}
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}

                                    >
                                        <div onClick={()=>props.history.push(`/news`)}  style={{display:'flex',marginLeft:10}}>
                                            <div style={{margin:'auto'}}><img style={{margin:'auto'}} height={'30px'} src={NovedadesIcon} /></div>
                                            <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Novedades`}</strong></MenuItem>
                                        </div>
                                        <div onClick={()=>props.history.push(`/notifications`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                            <div style={{margin:'auto'}}><img height={'30px'} src={WhatsIcon} /></div>
                                            <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Notificaciones`}</strong></MenuItem>
                                        </div>
                                        {props.me && [50,100].indexOf(props.me.role) > -1?
                                            <div onClick={()=>props.history.push(`/iotdevice/register`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                                <div style={{margin:'auto'}}><img height={'30px'} src={IOTIcon} /></div>
                                                <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Alta/Baja dispositivos`}</strong></MenuItem>
                                            </div>
                                        :
                                            null
                                        }
                                        {/* {props.me && [100].includes(props.me.role)?
                                            <div onClick={()=>props.history.push(`/createlocation`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                                <div style={{margin:'auto'}}><img height={'25px'} src={FindIcon} /></div>
                                                <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Crear ubicación`}</strong></MenuItem>
                                            </div>
                                        :
                                            null
                                        } */}

                                        {props.me && [50, 100].includes(props.me.role)?
                                            <div onClick={()=>props.history.push(`/iotdevice/find`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                                <div style={{margin:'auto'}}><img height={'25px'} src={FindIcon} /></div>
                                                <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Encontrar dispositivo`}</strong></MenuItem>
                                            </div>
                                        :
                                            null
                                        }
                                        {props.me && [50,100].indexOf(props.me.role) > -1?
                                            <div onClick={()=>props.history.push(`/invitations`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                                <div style={{margin:'auto'}}><img height={'30px'} src={InviteIcon} /></div>
                                                <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Invitar usuarios`}</strong></MenuItem>
                                            </div>
                                        :
                                            null
                                        }
                                        {props.me && [30,50,100].indexOf(props.me.role) > -1?
                                        <div onClick={()=>props.history.push(`/generalsettings`)} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                            <div style={{margin:'auto'}}><img height={'30px'} src={GeneralSettingsIcon} /></div>
                                            <MenuItem style={{width:'50vw', maxWidth:'200px'}} onClick={handleClose}><strong>{`Ajustes generales`}</strong></MenuItem>
                                        </div>
                                        :
                                            null
                                        }

                                        <div onClick={()=>handleNealiaHub()} style={{display:'flex',marginLeft:10, marginTop:10}}>
                                            <img style={{margin:'auto'}} height={'30px'} src={AppsIcon} />
                                            <MenuItem onClick={handleClose} style={{width:'50vw', maxWidth:'200px'}}><strong>{"Mis aplicaciones"}</strong></MenuItem>
                                        </div>

                                        <div onClick={()=>handleExitApp()} style={{display:'flex',marginLeft:10, marginTop:10, borderTop:'1px solid #BBBBBB'}}>
                                            <img style={{margin:'auto'}} height={'30px'} src={CloseSesionIcon} />
                                            <MenuItem onClick={handleClose} style={{width:'50vw', maxWidth:'200px'}}><strong>{"Cerrar sesión"}</strong></MenuItem>
                                        </div>

                                        <div style={{display:'flex',marginLeft:10, marginTop:10, borderTop:'1px solid #BBBBBB'}}>
                                            <a className='bar-user-name'>{props.me?.userName}</a> 
                                        </div>
                                    </Menu>
                                
                                {/*<div>*/}
                                    {/*<label style={{color:'#FFF'}}>{this.props.myInfo?.usuario}</label>*/}
                                    {/*<IconButton style={{marginLeft:5}} size="small" aria-label="Menú">
                                        <ArrowDropDownIcon style={{fill:'#FFF'}}/>
                                    </IconButton>
                                </div>*/}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {props.navBar?
                                <IconButton onClick={handleDrawerOpenClose} style={{marginLeft:5}} aria-label="Menu">
                                    <MenuIcon style={{fill:'#fff'}}/>
                                </IconButton>
                            :
                                null
                            }

                        </td>
                        <td colSpan={2}>
                            <div style={{textAlign:'center'}}>
                                <div style={{maxWidth:'900px', margin:'auto'}}>
                                    {props.onSearchChange?
                                        <SearchField
                                            value={props.searchValue}
                                            allowSearch={true}
                                            onValueChange={(evt:any)=>props.onSearchChange(evt)}
                                            placeholder={props.placeholderSearch}
                                            allowFilter={true}
                                            onFilterClick={()=>props.handleFilterClick()}
                                            onKeydown={(e:any)=>props.onKeydown(e)}
                                        />
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        </td>
                        <td >
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        {props.navBar?
            <Drawer navBar={props.navBar} onToggle={handleDrawerOpenClose} onItemClick={props.onItemClick} opened={opened} anchor={'left'}/>
        :
            null
        }
        <div style={{ position: 'absolute', height:25, bottom: 0, left: 0, right: 0, backgroundColor:'#ddd' }}>
            <div style={{paddingLeft:10}}>
                <span style={{fontSize:12}}><a href="https://www.nealia.tech/">{`Nealia Tech`}</a> &copy; {new Date().getFullYear()} (v1.2.3).</span>
            </div>
        </div>
        </div>
    );
}
