import React from 'react';
import { adalApiFetch } from './adalConfig';
import appData from './ApiClients/appData';
import greenPig from './Assets/googleMarkers/SmartMetrics_cerditoverde_25x25.png';
import greenChicken from './Assets/googleMarkers/SmartMetrics_polloverde_25x25.png';
import orangePig from './Assets/googleMarkers/SmartMetrics_cerditonaranja_25x25.png';
import orangeChicken from './Assets/googleMarkers/SmartMetrics_pollorojo_25x25.png';
import redChicken from './Assets/googleMarkers/SmartMetrics_pollorojo_25x25.png';
import redPig from './Assets/googleMarkers/SmartMetrics_cerditorojo_25x25.png';
import defaultMarker from './Assets/googleMarkers/DefaultMarker.png';
import { filter, split, reduce, find, cloneDeep, groupBy } from 'lodash';
import { IData, IMarker, IObjects, IIData } from './types';
import HumedadVerde from './Assets/widgets/SmartMetrics_humedad.png';
import HumedadRojo from './Assets/widgets/SmartMetrics_humedadrojo.png';
import TmpVerde from './Assets/widgets/SmartMetrics_temperaturaverde.png';
import TmpRojo from './Assets/widgets/SmartMetrics_temperatura.png';
import MedidorVerde from './Assets/widgets/SmartMetrics_v.png';
import MedidorRojo from './Assets/widgets/SmartMetrics_vrojo.png';
import Silo from './Assets/widgets/Silo_grayscale.png';
import Tmp from './Assets/widgets/Tmp_grayscale.png';
import Hmd from './Assets/widgets/Hmd_grayscale.png';
import Medidor from './Assets/widgets/Medidor_grayscale.png';
import BatteryFull from '@material-ui/icons/BatteryFullOutlined';
import BatteryMedium from '@material-ui/icons/Battery50Outlined';
import BatteryLow from '@material-ui/icons/Battery20Outlined';
import BatteryEmpty from '@material-ui/icons/BatteryAlertOutlined';
import BatteryUnknow from '@material-ui/icons/BatteryUnknownOutlined';
import {GetFormattedDate} from './vc_general';

import {inflateSync} from 'zlib';

const fetchAPI = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true
): Promise<any> => {
    return adalApiFetch(fetch, url, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            } else {
                return response.text();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}

const zLibInflate=(data:any)=>{

  const buff:any = Buffer.from(data,'base64');

  const output = JSON.parse(inflateSync(buff).toString());

  return output;
}

const checkHistorico=(dscode:string, dataType:number, dataDevices?:Array<IData>) =>{
  var result: any = []
  var dates:Array<number> = []
  var values:Array<number|IData> = []
  //const currentArray = appData.getHistoricoValues().filter(word => word.dsCode === dscode);
  //const currentArrayHistogramaPesos = appData.getHistoricoValues().filter(word => word.dsCode.includes(dscode));
  if(dataDevices == undefined)
    return([[],[]]);
  const currentArray = dataDevices.filter(word => word.dsCode === dscode);
  const currentArrayHistogramaPesos = dataDevices.filter(word => word.dsCode.includes(dscode));

  switch(dataType) {
    case 1:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.TENDVALUE)
      });
      result = [dates,values]
      break;
    
    case 2:
      currentArray.forEach(function(element) {
        dates.push(element.hour)
        values.push(element.ACUMVALUE)
      });
      result = [dates,values]
      break;
    case 3:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.TENDVALUE)
      });
      result = [dates,values]
      break;
    case 4:
      currentArray.forEach(function(element) {
          dates.push(element.hour)
          values.push(element.ACUMRATIO)
      });
      result = [dates,values]
      break;
  default:
    break;
  }
  return(result)
}
  
const checkOnlineValues=(object:string, dataType:number, dataDevices?:Array<IData>)=>{

  var result:any = ["Sin dato", "Sin fecha"]
  //const data = cloneDeep(appData.getOnlineValues());
  const data = cloneDeep(dataDevices);

  if(data == undefined){
    result = ["Cargando...", "Cargando..."];
    return result;
  }

  data.reverse();

  const currentArray = find(data, function(o){return o.dsCode == object} );

  if(currentArray == undefined)
      return(result);
  switch(dataType) {
      case 1:
          result = [currentArray.TENDVALUE, currentArray.dtRegister]
          break;
      
      case 2:
          result = [currentArray.ACUMVALUE, currentArray.dtRegister]
          break;
      case 3:
        var initialValue = 0;
        var sum = data.filter(word => word.dsCode === object ).reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.TENDVALUE;
        },initialValue)
        result = [parseFloat(sum.toFixed(2))]
        result.push(currentArray.dtRegister)
        break;
      case 4:
          var initialValue = 0;
          var sum = data.filter(word => word.dsCode === object).reduce(function (accumulator, currentValue) {
              return accumulator + currentValue.ACUMRATIO;
          },initialValue)
          result = [parseFloat(sum.toFixed(2))]
          result.push(currentArray.dtRegister)
          result.push(currentArray.numberPigs)
          break;
      default:
      break;
    }

    return result;
  }

const getColorMarker=(priority:number, tipo:number)=>{
  switch(tipo + "|" + priority) {
    case 1 + "|" + 0:
      return(greenPig);
    case 1 + "|" + 1:
    case 1 + "|" + 2:
      return(redPig);
    case 1 + "|" + 99:
      return(orangePig);
    case 2 + "|" + 0:
      return(greenChicken);
    case 2 + "|" + 1:
    case 2 + "|" + 2:
      return(redChicken);
    case 2 + "|" + 99:
      return(orangeChicken);
    default:
      return(defaultMarker)
  }
}

/*const getWidgetIcon=(tipo:number, priority:number)=>{
  switch(tipo + "|" + priority) {
    case 5 + "|" + 0:
      return(TmpVerde);
    case 5 + "|" + 1:
      return(TmpRojo);
    case 6 + "|" + 0:
      return(HumedadVerde);
    case 6 + "|" + 1:
      return(HumedadRojo);
    case 12 + "|" + 0:
      return(MedidorVerde);
    case 12 + "|" + 1:
      return(MedidorRojo);
    default:
      return(MedidorVerde);
  }
}*/
const getWidgetIcon=(tipo:number)=>{
  switch(tipo) {
    case 1:
      return(Tmp);
    case 2:
      return(Hmd);
    case 3:
      return(Silo);
    default:
      return(Medidor);
  }
}

const GetBatteryStatus=(status:number)=>{
  switch(true) {
    case status < 0.10:
      return([<BatteryEmpty style={{height:'auto', width:'40px'}}/>, '#E44A40', 'Sin batería']);
    case status < 0.25:
      return([<BatteryLow style={{height:'auto', width:'40px'}} />, '#A3D1F3', 'Batería baja']);
    case status < 0.5:
      return([<BatteryMedium style={{height:'auto', width:'40px'}}/>, '#FFCF5F', 'Media carga']);
    case status < 1.10:
      return([<BatteryFull style={{height:'auto', width:'40px'}} /*style={{fill:'#009900', backgroundColor:'#fff', borderRadius:'5px', height:'auto', width:'40px'}}*/ />, '#98CCA1', 'Bueno']);
    default:
      return([<BatteryUnknow style={{height:'auto', width:'40px'}}/>, '#F07937', 'Desconocido']);
  }
}

const GetFormattedSqlDate = (inputData:string, type:number) => {
  var datime = new Date(inputData);
  var month = datime.getMonth() + 1;
  var day = datime.getDate();
  var year = datime.getFullYear();
  return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+` ${type==1?'00:00:00':'23:59:59'}`;
}

const searchMarker = (inputData:Array<IMarker>, value:string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues = splitData(lowerCaseValue, ' ');

  let result = arrayValues.map((item:string)=>{
    return filter(inputData,function(marker){
      return marker.name.toLowerCase().indexOf(item) !==-1 ||
              marker.farmId.toLowerCase().indexOf(item) !==-1;
    });
  })
  return result[0];
}

const searchObject = (inputData:Array<IObjects>, value:string) => {
  let lowerCaseValue = value.toLowerCase();
  let arrayValues = splitData(lowerCaseValue, ' ');

  return [];
  let result = arrayValues.map((item:string)=>{
    return filter(inputData,function(object){
      return object.description.toLowerCase().indexOf(item) !==-1 ||
      object.dsCode.toLowerCase().indexOf(item) !==-1;
    });
  })
  return result[0];
}
const splitData = (input:string, char:string) => {
  return split(input, char);
}

const gDashboard=(input:Array<IObjects>, dashboard:string)=>{

  let result = filter(input, function(o){return o.dashboard == dashboard});

  return result;
}

const navBar=(input?:Array<IObjects>)=>{
  if(!input)
    return [];

  let result = filter(input, function(o){return o.objectType == 9}).map((item:IObjects, index)=>{
    return {
      id:item.id,
      name:item.description,
      route: `${item.farmId}/${item.dashboard}`
    }
  });

  return result;
}

function addThousandsAndReplaceDecimal(number:string|number, separatorDecimal:string){
  //let newNumber = String(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  let newNumber = String(number);
  newNumber = newNumber.replace('.',separatorDecimal);
  newNumber = newNumber.replace(',',separatorDecimal);
  newNumber = newNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return newNumber;
}

function addZeroes(num:number) {
  return num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
}

function DatasetDataFarm02(data:Array<IIData>){
  const grouped = groupBy(data, 'dsCode');

  const labels = data.map(e=>GetFormattedDate(e.dtRegister));
  const max = data.map(e=>e.max.toFixed(2));
  const avg = data.map(e=>e.avg.toFixed(2));
  const min = data.map(e=>e.min.toFixed(2));

  const ret = {
    labels: labels,
    datasets: [{
      label: 'Máxima',
      data: max,
      fill: false,
      borderColor: '#E44A40',
      tension: 0.1
    },
    {
      label: 'Media',
      data: avg,
      fill: false,
      borderColor: '#98CCA1',
      tension: 0.1
    },
    {
      label: 'Mínima',
      data: min,
      fill: false,
      borderColor: '#A3D1F3',
      tension: 0.1
    }]
  };

  return ret;
}

function DatasetDataFarm03(data:Array<IIData>){
  const grouped = groupBy(data, 'dsCode');

  const labels = data.map(e=>GetFormattedDate(e.dtRegister));
  const avg = data.map(e=>e.avg.toFixed(2));

  const ret = {
    labels: labels,
    datasets: [{
      label: 'Consumo diario',
      data: avg,
      fill: false,
      borderColor: '#98CCA1',
      backgroundColor: '#98CCA1',
      tension: 0.1,
      borderWidth: 1,
    }]
  };

  return ret;
}

function DatasetDataFarm04(data:Array<IIData>){
  const grouped = groupBy(data, 'dsCode');

  const labels = data.map(e=>GetFormattedDate(e.dtRegister));
  const avg = data.map(e=>e.avg.toFixed(2));

  const ret = {
    labels: labels,
    datasets: [{
      label: 'Variación diaria',
      data: avg,
      fill: false,
      borderColor: '#98CCA1',
      backgroundColor: '#98CCA1',
      tension: 0.1,
      borderWidth: 1,
    }]
  };

  return ret;
}

function DatasetDataFarm05(data:Array<IIData>){
  const grouped = groupBy(data, 'dsCode');

  const labels = data.map(e=>GetFormattedDate(e.dtRegister));
  const max = data.map(e=>e.max.toFixed(2));
  const avg = data.map(e=>e.avg.toFixed(2));
  const min = data.map(e=>e.min.toFixed(2));

  const ret = {
    labels: labels,
    datasets: [{
      label: 'Máxima',
      data: max,
      fill: false,
      borderColor: '#E44A40',
      tension: 0.1
    },
    {
      label: 'Media',
      data: avg,
      fill: false,
      borderColor: '#98CCA1',
      tension: 0.1
    },
    {
      label: 'Mínima',
      data: min,
      fill: false,
      borderColor: '#A3D1F3',
      tension: 0.1
    }]
  };

  return ret;
}

const findAllDevices = (tree:any) => {
  let devices:Array<IObjects> = [];
  if(!tree)
    return devices;
    
  for(let i=0;i<tree.length;i++){
    if(tree[i].objectType == 60){
      devices.push(tree[i]);
    } else if(tree[i].hasOwnProperty('children')){
      let ret:Array<IObjects> = findAllDevices(tree[i].children);
      for (let index = 0; index < ret.length; index++) {
        devices.push(ret[index]);        
      }
    }
  }

  return devices;
};

export { 
  fetchAPI, checkHistorico, checkOnlineValues,
  getColorMarker, GetFormattedSqlDate,
  searchMarker, navBar, getWidgetIcon, zLibInflate,
  gDashboard, addThousandsAndReplaceDecimal, addZeroes,
  findAllDevices,
  searchObject, GetBatteryStatus, DatasetDataFarm02,
  DatasetDataFarm03, DatasetDataFarm04,
  DatasetDataFarm05
}