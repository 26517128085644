import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IExploitation, IMarker, IMe, IObjects, IParte } from '../types';
import Loader from './Loader';
import Layout from './Layout';
import {cloneDeep} from 'lodash';
import WhatsIcon from '../Assets/SmartMetrics_whats.png';
import {CheckBoxField} from './FormsWidgets';
import ParteListItem from './ParteListItem';
import VirtualizedList from './VirtualizedList';
import OrderService from '../ApiClients/OrderService';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    },
    root: {
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    buttonSave: {
        marginTop:'15px', 
        display:'inline', 
        paddingTop:5,
        paddingBottom:5,
        paddingLeft:30, 
        paddingRight:30, 
        color:'#fff', 
        fontWeight:'bold', 
        backgroundColor:'#003B71',
        border:'0px solid',
        borderRadius:'5px',
        cursor:'pointer'
    }
});

interface IPartesProps {
    myInfo?: IMe;
    history: any;
    match: any;
    classes?:any;
    loading:boolean;
    updateMe:Function;
    badgeAlarms:number;
    loadedMarker?:IMarker;
    farmData?:IExploitation;
    loadExploitationData:Function;
}

interface IPartesState {
    me?:IMe;
    partes: Array<IParte>;
}

class Notificaciones extends Component<IPartesProps, IPartesState> {
    constructor(props: IPartesProps) {
        super(props);
        this.state = {
            me: props.myInfo,
            partes: [{id:"baja", name:`Bajas`, tipo: 1}, {id:"estado", name:`Estado granja`, tipo:2}]
        };
    }

    componentDidUpdate(prevprops:IPartesProps){
        if(prevprops.myInfo !== this.props.myInfo){
            this.setState({me:this.props.myInfo})
        }
    }

    componentDidMount(){
        this.LoadPartes();
    }

    LoadPartes = async (refresh?:boolean) => {
        const entityFarmId = Number(this.props.match.params.entityFarmId);
        const entityDataareaid = Number(this.props.match.params.entityDataareaid);    
        const marker = await OrderService.getMarker(entityFarmId,entityDataareaid);

        if(marker){
            this.props.loadExploitationData(marker, refresh);
        }
    }

    handleFieldChange=(id:string, evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[id] = evt.target.checked;
            this.setState({me:newMe});
        }
    }

    handleFieldTextChange=(evt:any)=>{
        let newMe = cloneDeep(this.state.me);
        if(newMe){
            newMe[evt.target.id] = evt.target.value;
            this.setState({me:newMe});
        }
    }

    handleItemClick=(parte:IParte)=>{
        console.log(parte);
    }

    handleOnDrawerItemClick=(e:any, route:IObjects, type:number)=>{
        if(type == 1) // Cuando es una dashboards
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/dashboard/${route.id}`);
        else if(type == 2) // Cuando se tiene que ir al histórico
            this.props.history.push(`/${route.entityDataareaid}/${route.entityFarmId}/historic`);
    }

    render() {
        const {myInfo, classes, loading, badgeAlarms, updateMe, farmData, loadedMarker} = this.props;
        const {me, partes} = this.state;

        let items = partes.map((parte:IParte) => {
            return {
                ...parte,
                onItemClick: () => this.handleItemClick(parte),
            }
        })

        return (
            <div>
            <Layout me={myInfo} onItemClick={(e:any, item:any, type:number)=>this.handleOnDrawerItemClick(e,item, type)} navBar={farmData?.data.navbar} marker={loadedMarker} history={this.props.history} searchValue={``} placeholderSearch={``} badgeAlarms={badgeAlarms}/>

                    <div className='dashboard-content'>

                        {me && farmData?
                            <div style={{marginTop:10, marginLeft:10}}>
                                <div style={{marginBottom:30}}>
                                    <strong style={{fontSize:'18px'}}>{`Partes`}</strong>
                                    <p>
                                        {`Sección donde podrás consultar o generar tus partes de la granja.`}
                                    </p>
                                </div>
                                <div style={{
                                    position: 'absolute',
                                    top: '110px',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <VirtualizedList items={items} RowComponent={ParteListItem} itemSize={80} />
                                </div>
                            </div>
                        :
                            loading?
                                <Loader loading={true}/>
                            :
                                <Loader loading={true}/>
                        }
                    </div>

            </div>
        );
    }
}

export default withStyles(styles)(Notificaciones);